// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";

// VARIABLES

// SCSS
.PropertyDetails {
  #navigation .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  #galery {
    .image-grid {
      display: grid;
      grid-template-rows: auto 130px;
      grid-template-columns: auto 130px 130px;
      grid-template-rows: 130px 130px;
      gap: 20px;
      grid-template-areas:
        "item1 item2 item3"
        "item1 item4 item5";

      .item1 {
        grid-area: item1;
        background-color: $gray-500;
      }

      .item2 {
        grid-area: item2;
        background-color: $gray-500;
      }

      .item3 {
        grid-area: item3;
        background-color: $gray-500;
      }

      .item4 {
        grid-area: item4;
        background-color: $gray-500;
      }

      .item5 {
        grid-area: item5;
        background-color: $gray-300;
        color: $gray-700;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom: 0px;
        }
        cursor: pointer;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 130px 130px 130px;
        grid-template-areas:
          "item1 item1"
          "item2 item4"
          "item3 item5";
      }

      @media screen and (max-width: 480px) {
        gap: 5px;
      }
    }
  }

  #contact {
    .contact-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .main-info {
        display: flex;
        align-items: center;
        margin-right: 25px;
        position: relative;
        .image-wrapper {
          height: 66px;
          width: 66px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 4px solid $white;
          border-radius: 33px;
          margin-right: 25px;
          position: relative;
          img {
            height: 60px;
            width: 60px;
            border-radius: 30px;
            object-fit: cover;
          }
          .circle {
            position: absolute;
            height: 56px;
            width: 56px;
            border-radius: 56px;
            border: 1px solid $white;
          }

          .edit {
            cursor: pointer;
            bottom: 2px;
            left: 40px;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 22px;
            width: 22px;
            background-color: $white;
            border-radius: 11px;
            .icon {
              padding: 2px;
            }
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .actions-staff {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > div {
          display: flex;
          align-items: center;
        }
        .number {
          margin-left: 10px;
          margin-right: 20px;
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        .main-info {
          margin-right: 0px;
          align-items: flex-start;
          .image-wrapper {
            margin-right: 10px;
            margin-top: 2px;
          }
        }
        .actions-staff {
          flex-direction: column;
          bottom: 30px;
          width: 100%;
          padding: 0px 5px;
          .btn {
            min-width: 150px;
            margin: 10px 0px;
            flex: 1;
            width: max-content;
          }

          .number {
            margin-right: 0px;
          }
        }
      }
      @media screen and (max-width: 480px) {
        .main-info {
          align-items: center;
          flex-direction: column;
          h3,
          span {
            text-align: center;
          }
          .image-wrapper {
            margin-right: 0px;
          }
          .actions-staff {
            flex-direction: column;
            align-items: flex-start;
            .btn {
              margin: 10px 0px;
              flex: 1;
              width: max-content;
            }
          }
        }
      }
    }
  }

  .lightText {
    color: $gray-500;
    @include desktop-meta-data-medium-italic-10;
  }

  .pl-20 {
    padding-left: 10px;
  }

  h6 {
    color: $black;
    margin-bottom: 0;
  }
  .name-section {
    margin-left: 14px;

    .property {
      margin-top: 3px;
      > div:first-child {
        color: $black;
      }
      .badge {
        margin-left: 10px;
      }
    }
  }

  .tabbar {
    display: flex;
    align-items: end;
  }
}
