// layouts/People/People.style.scss

// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.reports-people {
  > .input-wrapper {
    margin: 0px;
  }
}
