// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS

.modal-header {
  margin-bottom: -60px;
  align-items: flex;

  .horizontal-header {
    padding: 0px 25px 0px 10px;

    .icon {
      &:hover {
        cursor: pointer;
      }
    }
    @media screen and (max-width: 650px) {
      padding: 0px 5px 0px 10px;
    }
  }
}

.user-settings-container {
  gap: 80px;
  padding: 30px;
  @media screen and (max-width: 650px) {
    flex-direction: column;
    padding: 10px;
    gap: 50px;
    align-items: center;
  }
}

.reauthentication {
  flex-direction: row;

  .text {
    width: 205px;
    margin-top: 12px;
    margin-bottom: 10px;
  }
}

.edit-profile-photo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  .photo {
    height: 150px;
    width: 150px;
    border-radius: 75px;
    border: 1px solid $gray-300;
    background-color: $gray-200;
    margin-bottom: 20px;
  }

  .text {
    font-size: 12px;
    font-weight: 400;
    margin-top: 5px;
    white-space: nowrap;
  }
  .h6-semibold-16 {
    @media screen and (max-width: 250px) {
      text-align: start;
      width: 100%;
    }
  }
}

.edit-profile-container {
  max-width: 355px;
  width: 100%;

  .user-settings-form {
    margin-top: -8px;
  }

  .change-password-link {
    color: #008489;
    font-weight: 400;
    font-size: 14px;

    &:hover {
      cursor: pointer;
    }
  }

  .border {
    width: 100%;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 25px;
    margin-top: 25px;
  }

  .notification-container {
    margin-bottom: 20px;
  }

  .checkbox-container {
    margin-top: 10px;
  }

  .reauthenicate-error {
    margin-top: -30px;
    margin-bottom: 20px;
  }

  .button-container {
    width: 100%;
    display: flex;
    flex: 1 1 auto;
    gap: 10px;
    justify-content: center;
    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
  }
}
