// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";

#access-points {
  .spinner {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.75);
  }

  .schedule-link {
    color: $teal;
    cursor: pointer;

    & .installed {
      text-decoration: line-through;
    }

    &.disabled {
      color: $gray;
      cursor: none;
    }
  }

  .tabbar-section {
    .container {
      padding: 0;
    }

    .tabbar-place {
      height: 45px;
      flex-direction: row;

      > ul.tabbar {
        margin: 0px;

        width: 100%;
      }

      .full-access-row {
        flex-shrink: 0;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: nowrap;
        margin-left: 20px;
        margin-right: 20px;
        .property-access-toggle-wrapper {
          display: flex;
          align-items: center;
        }
      }

      @media screen and (max-width: 740px) {
        flex-direction: column;
        height: 95px;
      }
    }
  }

  .full-access-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-right: 10px;
    margin-left: 10px;
    padding-top: 10px;

    hr {
      margin-top: 10px;
      margin-bottom: 10px;
      min-width: 100%;
    }

    h6 {
      flex: 1;
      text-align: right;
      margin: 0 15px 0 20px;
    }
  }

  h6 {
    margin-top: 14px;
    margin-bottom: 7px;
  }

  .access-actions {
    display: flex;
    max-height: 500px;

    .areas {
      min-width: 252px;
      padding: 11px;
      margin-top: 15px;
      background-color: $gray-200;
      border-radius: 5px;
      margin-right: 20px;
      overflow-y: scroll;

      .building {
        display: flex;
        color: $black;
        align-items: center;
        padding: 11px 15px;
        cursor: pointer;

        &.active {
          border-left: 3px solid $teal;
          background-color: $white;
          @include desktop-label-semibold-14;
          cursor: default;
        }

        &.with-access:after {
          content: "";
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 5px;
          background: $teal;
          margin-left: 10px;
        }
      }
    }

    #access-points-list {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;

      #access-grid {
        height: 100%;
        min-height: 300px !important;

        thead {
          .data-grid-cell:last-child {
            display: flex;
            justify-content: flex-end;
          }
        }

        tr {
          td:first-child {
            @include body-small-light-12;
          }
          td:nth-child(2) {
            color: $red;
          }
          td:last-child {
            .input-toggle {
              justify-content: flex-end;
            }
          }
        }
        @media screen and (max-width: 740px) {
          height: 400px;
        }
      }
    }

    @media screen and (max-width: 740px) {
      flex-direction: column;
      max-height: min-content;
      .areas {
        margin-right: 0px;
      }
    }
  }
}
