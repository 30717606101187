// IMPORTS
@import '../../styles/variables/variables';
@import '../../styles/functions/functions';
@import '../../styles/mixins/mixins';

// VARIABLES


// SCSS
.dropdown {
  display: inline-block;
  position: relative;


  .dropdown-toggle {}

  .dropdown-menu {
    position: absolute;
    z-index: $zindex-dropdown;
    margin: 5px 0;
    box-shadow: $box-shadow;

    &.direction-down {
      top: 100%;
    }

    &.direction-up {
      bottom: 100%;
    }

    &.align-left {
      left: 0;
    }

    &.align-right {
      right: 0;
    }
  }
}
