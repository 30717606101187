// IMPORTS
@import '../../styles/variables/variables';
@import '../../styles/functions/functions';
@import '../../styles/mixins/mixins';

// VARIABLES
$chip-size: 26px;

// SCSS
.chip {
  display: inline-flex;
  position: relative;
  align-items: center;
  margin: 4px;
  border: 1px solid $gray-100;
  border-radius: ($chip-size / 2);
  background-color: $gray-200;
  padding: 5px 10px;
  min-width: $chip-size;
  height: $chip-size;
  line-height: 1em;
  font-size: $font-size-sm;
  font-weight: $font-weight-semibold;

  &.dismissible {
    padding-right: 1px;
  }

}
