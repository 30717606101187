.confirm-modal {
  .title {
    margin-bottom: 20px;
  }

  .message {
    margin-bottom: 20px;
  }

  .controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 15px;
  }
}
