@import "../variables/typography";

@mixin button-bold-14 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 14px;
  line-height: 18px;
}

.button-bold-14 {
  @include button-bold-14;
}

@mixin body-large-light-16 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 28px;
}

.body-large-light-16 {
  @include body-large-light-16;
}

@mixin h1-bold-46 {
  font-style: normal;
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-bold;
  font-size: 46px;
  line-height: 54px;
}

.h1-bold-46 {
  @include h1-bold-46;
}

@mixin h6-semibold-16 {
  font-style: normal;
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-semibold;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.2px;
}

.h6-semibold-16 {
  @include h6-semibold-16;
}

@mixin body-light-14 {
  font-style: normal;
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-light;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.3px;
}

.body-light-14 {
  @include body-light-14;
}

@mixin body-small-light-12 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: $font-weight-light;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.body-small-light-12 {
  @include body-small-light-12;
}

@mixin label-small-regular-12 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: $font-weight-semibold;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.label-small-regular-12 {
  @include label-small-regular-12;
}

@mixin desktop-label-semibold-14 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: $font-weight-semibold;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.3px;
}

.desktop-label-semibold-14 {
  @include desktop-label-semibold-14;
}

@mixin tag-semibold-10 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: $font-weight-semibold;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.2px;
}

.tag-semibold-10 {
  @include tag-semibold-10;
}

@mixin mobile-page-header-20 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: $font-weight-regular;
  letter-spacing: 0.1px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
}

.mobile-page-header-20 {
  @include mobile-page-header-20;
}

@mixin page-statistic-26 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  letter-spacing: 0.2px;
  font-weight: $font-weight-light;
  font-size: 26px;
  line-height: 30px;
}

.page-statistic-26 {
  @include page-statistic-26;
}

@mixin desktop-meta-data-medium-italic-10 {
  font-family: $font-family-sans-serif;
  font-style: italic;
  font-weight: $font-weight-medium;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.2px;
}

.desktop-meta-data-medium-italic-10 {
  @include desktop-meta-data-medium-italic-10;
}

// Desktop - H4 - Semibold 22 monseratt
@mixin desktop-h4-semibold-22 {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: $font-weight-semibold;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.2px;
}

.desktop-h4-semibold-22 {
  @include desktop-h4-semibold-22;
}
