// IMPORTS
@import '../../styles/variables/variables';
@import '../../styles/functions/functions';
@import '../../styles/mixins/mixins';

// VARIABLES


// SCSS
.dialogue {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 100;
  max-height: 100vh;
  overflow: hidden;

  .gobo {  // background
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: rgba($black, .25);
  }

  .dialogue-window {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    border-radius: $border-radius;
    background: $white;
    padding: $spacing-sm;
    width: 350px;
    max-width: 350px;

    .message {
      padding-bottom: $spacing-sm;
      font-size: $font-size;
    }
  }
}
