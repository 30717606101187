// IMPORTS
@import '../../styles/variables/variables';
@import '../../styles/functions/functions';
@import '../../styles/mixins/mixins';

// VARIABLES
$progress-bar-height: 15px;

// SCSS
.progress-bar {
  position: relative;
  margin: 5px 0;
  background-color: $gray-200;
  height: $progress-bar-height;

  .label {
    padding: 0 $spacing-sm;
  }

  .progress {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: $green;
    height: $progress-bar-height;
    overflow: hidden;
    .percent {
      color: $white;
    }
  }

  .percent {
    position: absolute;
    z-index: 1;
    padding: 0 $spacing-sm;
    line-height: $progress-bar-height;
    font-size: $font-size-sm;
  }
}
