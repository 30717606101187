// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.LocksActivityLogs {
  table {
    th:last-child {
      text-align: right;
    }
    td:last-child {
      text-align: right;
      color: $gray-400;
    }
    .last-login-col {
      color: $gray-400;
    }
  }
}
