// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES
$checkbox-size: 20px;
$checkbox-border-color: $border-color;
$checkbox-border-color-checked: #008489;
$checkbox-border-color-hover: #008489;
$checkbox-background: $white;
$checkbox-background-checked: #008489;
$checkbox-background-hover: #008489;
$check-width: 2px;
$check-color: $white;
$disabled-color: $checkbox-border-color;

// SCSS
.checkbox {
  display: flex;
  align-items: center;
  margin: $spacing-xs 0;

  label {
    display: inline-flex;
    position: relative;
    align-items: flex-start;
    //justify-content: flex-start;
    cursor: pointer;
    padding: 0 5px 0 0;
    min-height: $checkbox-size;

    line-height: $checkbox-size;
    color: $gray-700;
    font-weight: $font-weight;
    user-select: none;

    &::before {
      // the checkbox
      display: block;
      transition: background 200ms ease-out;
      margin-right: 10px;
      border: 1px solid $checkbox-border-color;
      border-radius: $border-radius;
      background: $checkbox-background;
      width: $checkbox-size;
      min-width: $checkbox-size;
      height: $checkbox-size;
      text-align: center;
      content: "";
    }

    &::after {
      // the check
      display: block;
      position: absolute;
      top: $checkbox-size * 0.12;
      left: $checkbox-size * 0.32;
      transform: rotate(45deg);
      transition: border-color 0.3s ease;
      opacity: 0;
      border-right: $check-width solid $check-color;
      border-bottom: $check-width solid $check-color;
      background-color: transparent;
      width: $checkbox-size * 0.35;
      height: $checkbox-size * 0.65;
      content: "";
    }

    &:hover {
      color: $checkbox-background-hover;

      &::before {
        border-color: $checkbox-border-color-hover;
      }
    }

    .required {
      color: $danger;
    }

    &.checked {
      &::before {
        border-color: $checkbox-border-color-checked;
        background: $checkbox-background-checked;
        content: "";
      }

      &::after {
        opacity: 1;
        content: "";
      }
    }

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
      color: $disabled-color;

      &::before {
        border-color: $disabled-color;
        background: $gray-100;
      }

      &::after {
        opacity: 0;
      }

      &:hover {
        color: $disabled-color;
      }

      &.checked {
        &::before {
          border-color: $disabled-color;
          background: $disabled-color;
        }

        &::after {
          opacity: 1;
          border-color: $white;
        }
      }
    }

    &.is-invalid {
      color: $danger;

      &::before {
        border-color: $danger;
      }

      &:before {
        background-color: $danger;
      }
    }
  }

  input[type="checkbox"] {
    @include visually-hidden();

    &.is-invalid:checked {
      + label {
        &:before {
          background-color: $danger;
        }
      }
    }

    &:focus:not(:disabled) {
      + label {
        color: $blue-light;

        &:before {
          @include add-focus;
        }
      }
    }
  }
}
