// IMPORTS
@import '../../styles/variables/variables';
@import '../../styles/functions/functions';
@import '../../styles/mixins/mixins';

// VARIABLES


// SCSS
.sidebar-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: $border-radius;
  padding: 5px 10px;
  width: 100%;
  text-decoration: none;
  color: $gray-500;

  &:hover {
    background-color: $gray-100;
    color: $black;
  }

  &.active {
    background-color: $gray-200;
    color: $black;
    font-weight: $font-weight-semibold;
  }

  .menu-item-icon {
    padding: 5px;
  }

  .menu-item-label {
    flex: 1 0 auto;
  }

  .menu-item-badge {
    padding: 5px;
  }
}
