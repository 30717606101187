// IMPORTS
@import "../../../styles/variables/variables";
@import "../../../styles/functions/functions";
@import "../../../styles/mixins/mixins";

// SCSS
.PendingInviteOverview {
  .mobile-page-header-20 {
    color: $black;
  }

  .spinner-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.7);
  }

  .invite-cell {
    position: relative;
  }

  .invite-button {
    opacity: 0;
  }

  .invisible-invite-button {
    visibility: hidden;
  }

  .no-devices-label {
    top: 50%;
    opacity: 1;
    position: absolute;
    font-weight: 600;
    transform: translateY(-50%);
    color: rgb(240, 76, 92);
  }

  .invited-label {
    opacity: 1;
    top: 50%;
    left: 25%;
    position: absolute;
    font-weight: 600;
    transform: translate(-50%, -50%);
  }

  tr {
    &:hover {
      .invite-button {
        opacity: 1;
      }

      .invited-label {
        opacity: 0;
      }
    }
  }
}
