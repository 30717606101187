// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/variables/colors";

// VARIABLES

// SCSS
.input-label {
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 1.5px;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  font-family: $font-family-mulish-sans-serif;
  color: $gray-700;
  .tooltip {
    margin-left: 5px;
  }
}
