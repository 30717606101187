// IMPORTS
@import "../../../../styles/variables/variables";
@import "../../../../styles/functions/functions";
@import "../../../../styles/mixins/mixins";

// SCSS
.OverviewNotes {
  margin-bottom: 20px;

  .noteCard {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;

    &Info {
      margin-left: 15px;
      width: 100%;
    }
    &Header {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
    }
    &Name {
      flex: 1;
      display: inline-block;
    }
  }
}
