// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/variables/typography";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

$drawer-width: 340px;
// SCSS
.drop-shadow {
  transition: all 1s ease-out;
  top: 0%;
  right: 0%;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 999;
  background-color: rgba($color: $black, $alpha: 0.3);
  cursor: pointer;
  &-hidden-left,
  &-hidden-right {
    background-color: rgba($color: $black, $alpha: 0);
    visibility: hidden;
  }
  transition: all 0.25s ease-out;
  .drawer {
    cursor: default;
    top: 0px;
    right: 0px;
    z-index: 1;
    position: absolute;
    width: $drawer-width;
    height: 100%;
    background-color: $white;

    &-left {
      right: unset;
      left: 0px;
    }

    &-hidden-left {
      right: unset;
      left: -$drawer-width;
    }

    &-hidden-right {
      left: unset;
      right: -$drawer-width;
    }

    transition: all 0.25s ease-out;
  }
}
