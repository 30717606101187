// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";
@import "../../styles/variables/colors";

// VARIABLES

.toaster-container {
  position: fixed;
  padding: 10px;
  bottom: 20px;
  right: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
}

.toast-wrapper {
  position: relative;
  padding: 20px;
  background-color: #fff;
  border: 1px solid $gray-200;
  box-shadow: 0px 0px 5px $gray-200;
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  transition: all 0.3s ease;
  animation: slide-up 300ms ease;

  @keyframes slide-up {
    from {
      transform: translateY(100vh);
    }
    to {
      transform: translateY(0vh);
    }
  }

  &.hide {
    opacity: 0;
  }

  .toast-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
