// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.accordion {
  margin: 0px;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  background-color: $white;
  overflow: hidden;

  &.transparent {
    background-color: transparent;
    box-shadow: none;
  }

  .accordion-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px;
    height: 50px;
    > div:first-child {
      flex: 1;
    }

    &.expanded + .accordion-body {
      display: block;
      padding: 10px;
      max-height: 300px;
      overflow-y: auto;
    }
  }

  .accordion-body {
    @include scrollbar;
    transition: 0.2s ease;
    padding: 0 10px;
    max-height: 0;
    overflow-y: hidden;
  }

  &.expanded {
    .accordion-head {
    }

    .accordion-body {
    }
  }
}
