// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.TableNumberOfItems {
  .input-wrapper {
    height: 32px;
    padding-left: 10px;
    width: 160px;
    background-color: $white;
    border-radius: 5px;
    select {
      height: 32px;
      border: 0px;
      padding: 0px;
    }
    label {
      display: none;
    }
  }
}
