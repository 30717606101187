// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";
@import "../../styles/variables/colors";

// VARIABLES
// SCSS
.login-layout {
  display: flex;
  flex-direction: row;
  height: 100%;

  .login-col {
    flex: 1 0 50%;
    padding: $spacing-md;
    max-width: 50%;

    &.left {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $brand-primary;
      color: $white;
      padding: $spacing-md 0 $spacing-md 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      .carousel-item {
        flex-direction: column;

        .illustration {
          width: 100%;
          max-width: 604px;
          margin-bottom: 62px;
          height: auto;
        }
        .page-carousel-wrapper {
          display: flex;
          flex-direction: column;
          max-width: 494px;
          white-space: normal;

          text-align: center;
          height: 400px;

          .page-carousel-wrapper-object {
            @include button-bold-14;
          }
          .page-carousel-wrapper-title {
            @include h1-bold-46;
          }
          .page-carousel-wrapper-description {
            margin-top: 61px;
            @include body-large-light-16;
          }
        }
      }
    }

    &.right {
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scroll-behavior: smooth;
    .login-col {
      flex: 1 0 50%;
      max-width: 100%;
    }
    .left {
      display: none !important;
      height: fit-content;
    }
    .right {
      padding: 0px;
      height: fit-content;
      flex: 1;
    }
  }
}
