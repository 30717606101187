// IMPORTS
@import "../../../styles/variables/variables";
@import "../../../styles/functions/functions";
@import "../../../styles/mixins/mixins";

// VARIABLES

// SCSS
.Vendors {
  #add-staff {
    opacity: 0;
  }

  tr {
    &:hover {
      #add-staff {
        opacity: 1;
      }
    }
  }
}
