// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.card {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  margin: $spacing-xs 0;
  border: 0;
  border: $border-radius;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  background: $white;
  overflow: hidden;

  &.padding-md {
    padding: 20px;
  }

  &.padding-sm {
    padding: 10px;
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      .card-body {
        background: $white;
      }
    }
  }

  &.image-side {
    flex-direction: row;
  }

  &.gray {
    background-color: $gray-100;
  }

  &.dark {
    background-color: $gray-200;
    box-shadow: none;
  }

  &.no-shadow {
    background-color: $gray-100;
    box-shadow: none;
  }

  &.no-margin {
    margin: 0;
  }

  .card-image {
    display: block;
    flex-basis: auto;
    background-color: $gray-300;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100px;
    min-height: 100px;
  }
}
