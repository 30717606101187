@import "../../../styles/variables/variables";

.Transactions {
  table {
    td.datetime {
      color: $gray-400;
      width: 22rem;
    }
    td.actions {
      width: 25rem;

      .actions-row {
        display: flex;
        gap: 12px;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }
}
