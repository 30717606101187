@import "../../../styles/variables/variables";

.EmailRegistration {
  .panel {
    position: relative;
  }

  // .formRow {
  //   display: flex;
  //   flex-direction: row;
  //   align-items: flex-end;
  //   margin-bottom: 30px;
  //   gap: 12px;

  //   .input-wrapper {
  //     margin-top: 0;
  //     margin-bottom: 0;
  //   }
  // }
}
