// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";

// VARIABLES

// SCSS
.StaffOverview {
  .breadcrumbs-container {
    @media screen and (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
      ol {
        flex-wrap: wrap;
      }
    }
  }
  .lightText {
    color: $gray-500;
    @include desktop-meta-data-medium-italic-10;
  }

  .pl-20 {
    padding-left: 10px;
  }

  h6 {
    color: $black;
    margin-bottom: 0;
  }

  h4 {
    color: $black;
    margin-bottom: 0;
  }

  hr {
    border: none;
    border-top: 1px solid $gray-300;
  }

  hr {
    margin-top: 16px;
    margin-bottom: 9px;
  }

  #contact-card {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    align-items: center;

    .person-card {
      grid-column: 1 / span 2;
    }

    .activity-container {
      justify-content: flex-end;
    }

    @media screen and (max-width: 1400px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media screen and (max-width: 1270px) {
      .person-card {
        grid-column: 1;
      }

      .activity-container {
        justify-content: flex-start;
      }

      grid-template-columns: 2fr repeat(1, 1fr);
    }
    @media screen and (max-width: 1270px) {
      .person-card {
        grid-column: 1;
      }
      grid-template-columns: 2fr repeat(1, 1fr);
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    @media screen and (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
      .person-card > div {
        flex-direction: column;
        align-items: flex-start;
        .badge {
          margin-left: 5px;
        }
        .name-section {
          margin-top: 10px;
          margin-left: 0px;
        }
      }

      @media screen and (max-width: 299px) {
        grid-template-columns: repeat(1, 1fr);
        .person-card > div {
          .badge {
            margin-left: 0px;
            margin-top: 5px;
          }
        }
      }
    }
  }

  #staff-assigned-properties {
    min-height: 165px !important;
  }
}

#employee-phone-modal {
  .modal-controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 15px;
  }
}
