// IMPORTS
@import "../../../styles/variables/variables";
@import "../../../styles/functions/functions";
@import "../../../styles/mixins/mixins";

// VARIABLES

// SCSS
#PropertyCard {
  padding: 20px;
  background-color: $white;
  border-radius: 5px;
  display: flex;
  cursor: pointer;

  .card-container {
    .property-image {
      height: 163px;
      width: 150px;
      object-fit: cover;
      border-radius: 5px;
      margin-right: 15px;
    }

    .header-container {
      .header-property-card {
        margin-right: 15px;
      }
    }

    .details {
      margin-top: 15px;
      padding: 10px 15px;
      background-color: $gray-100;
      border-radius: 5px;

      .manager {
        flex-wrap: wrap;
        img {
          height: 30px;
          width: 30px;
          border-radius: 15px;
          object-fit: cover;
        }

        span {
          margin-left: 15px;
          margin-right: 10px;
        }
      }

      hr {
        margin: 10px 0px;
      }
    }

    p {
      margin: 0;
    }
  }

  @media screen and (max-width: 480px) {
    margin: 0px 10px;
    .card-container {
      flex-direction: column;

      .property-image {
        width: 100%;
        margin-bottom: 15px;
        margin-right: 0px;
      }

      .header-container {
        flex-direction: column;
        .header-property-card {
          margin-bottom: 10px;
        }
      }

      .info {
        flex-direction: column;
      }
    }
  }
}
