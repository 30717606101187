// ==================================================
// Typography
// ==================================================

// FONTS PATH
$font-path: "../fonts";

// FONT FAMILY
$font-family-mulish-sans-serif: "Mulish", sans-serif;
$font-family-sans-serif: "Montserrat", sans-serif; //'Montserrat', Arial, sans-serif;
$font-family-serif: "Bree Serif", Georgia, "Times New Roman", Times, serif;
$font-family-monospace: "Roboto Mono", Consolas, "Liberation Mono", monospace;

$font-family-base: $font-family-sans-serif;

// FONT SIZES
$root-pixel: 10px; // the font size on the HTML tag
$font-size-base: 1.4rem;

$font-size-footnote: 1rem; // 12 px min is recommended min size for readability
$font-size-xs: 1rem;
$font-size-sm: 1.2rem;
$font-size: $font-size-base;
$font-size-lg: 1.6rem;
//$font-size-xl:       2.4rem;

// LINE HEIGHT
$line-height-sm: 1.2;
$line-height: 1.4;
$line-height-lg: 1.8;

$line-height-base: $line-height;

// FONT COLOR
$font-color: $gray-700;
$heading-color: $black;

// FONT WEIGHT
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

$font-weight-base: $font-weight-medium;
$font-weight: $font-weight-medium;

// max width comfortable for large paragraphs of text on the web.
$max-paragraph-width: 720px;
