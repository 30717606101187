// IMPORTS
@import '../../styles/variables/variables';
@import '../../styles/functions/functions';
@import '../../styles/mixins/mixins';

// VARIABLES


// SCSS
.input-invalid-message {
  @include ellipsis();
  display: inline-flex;
  align-items: flex-end;
  margin-top: 5px;
  color: $danger;
  font-size: $font-size-sm;

  .icon {
    margin: 0 5px 0 0;
  }
}
