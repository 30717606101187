// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES
$icon-size-xs: 16px;
$icon-size-sm: 24px;
$icon-size-md: 40px;
$icon-size-lg: 50px;

// SCSS
.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  flex-shrink: 0;

  // SIZES
  &.xs {
    padding: 2px;
    width: $icon-size-xs;
    height: $icon-size-xs;
  }

  &.sm {
    padding: 4px;
    width: $icon-size-sm;
    height: $icon-size-sm;
  }

  &.md {
    padding: 8px;
    width: $icon-size-md;
    height: $icon-size-md;
  }

  &.lg {
    padding: 10px;
    width: $icon-size-lg;
    height: $icon-size-lg;
  }

  // THEMES
  &.default {
    color: currentColor;
  }

  &.tooltip {
    color: $teal;
  }

  &.primary {
    background: $gray-200;
    color: $teal;
  }

  &.info {
    background: $info-bkg;
    color: $info;
  }

  &.success {
    background: $success-bkg;
    color: $success;
  }

  &.warning {
    background: $warning-bkg;
    color: $warning;
  }

  &.error,
  &.danger {
    background: $danger-bkg;
    color: $danger;
  }
}
