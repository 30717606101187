// IMPORTS
@import '../../styles/variables/variables';
@import '../../styles/functions/functions';
@import '../../styles/mixins/mixins';

// VARIABLES


// SCSS
.thumbnail {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  background-color: $info-bkg;
  overflow: hidden;

  img {
    position: absolute;
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  // SIZES
  &.xs {}

  &.sm {
    width: 40px;
    height: 40px;
  }

  &.md {
    width: 130px;
    height: 130px;
  }

  &.lg {
    width: 280px;
    height: 280px;
  }

  &.xl {
    width: 768px;
    height: 280px;
  }
}
