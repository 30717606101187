.structures-filter {
  padding-right: 10px;

  .accordion-body::-webkit-scrollbar {
    width: 0;
  }

  .accordion-head,
  .accordion-body,
  &.accordion .accordion-head.expanded + .accordion-body {
    padding-right: 0;
  }

  &.accordion > .accordion-head.expanded + .accordion-body {
    max-height: unset;
  }
}
