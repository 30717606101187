// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";
@import "../../styles/variables/colors";

// VARIABLES

.access-devices {
  h4 {
    margin-bottom: 20px;
  }

  .collapsible-list label {
    color: #000;

    &:hover {
      color: #000;
    }
  }

  .device-label {
    margin-right: 1em;
  }

  .link {
    color: $teal;
    cursor: pointer;
  }
}
