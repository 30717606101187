// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.tabbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0 10px;
  border-bottom: 1px solid $gray-300;
  padding: 0;
  list-style: none;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-color: transparent transparent;
  li {
    min-width: fit-content;
  }
}
