// IMPORTS
@import "../../../styles/variables/variables";
@import "../../../styles/functions/functions";
@import "../../../styles/mixins/mixins";

// VARIABLES

// SCSS
.Guests {
  .deactivatePersonButton button {
    opacity: 0;
  }

  tr:hover {
    .deactivatePersonButton button {
      opacity: 1;
    }
  }
}
