// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.data-grid {
  overflow: auto;
  position: relative;
  th.data-grid-cell {
    &.sortable {
      cursor: pointer;

      &.selected &:hover {
        // background-color: $gray-100;
      }
    }
  }
  td {
    vertical-align: middle !important;
  }

  .selectable {
    cursor: pointer;
  }

  thead {
    th {
      white-space: nowrap;
    }

    .table-header-cell-content {
      display: flex;
      align-items: center;
    }

    .table-header-cell-icon {
      width: 24px;
      height: 24px;
    }
  }

  tbody {
    tr {
      td {
      }
    }
  }
  .empty-wrapper {
    text-align: center;
    position: absolute;
    left: 50%;
    top: calc(50% + 30px);
    transform: translate(-50%, -50%);
    color: $gray-400;
  }
}
