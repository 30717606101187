// DISPLAY
// ==================================================
.d-flex {
  display: flex;

  &-sm {
    @media #{$target-sm-up} {
      display: flex;
    }
  }

  &-md {
    @media #{$target-md-up} {
      display: flex;
    }
  }

  &-lg {
    @media #{$target-md-up} {
      display: flex;
    }
  }

  &.fl1 {
    flex: 1;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.flex-column {
    flex-direction: column;
  }

  &.align-center {
    align-items: center;
  }

  &.align-start {
    align-items: start;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.justify-center {
    justify-content: center;
  }
}

.d-block {
  display: block;

  &-sm {
    @media #{$target-sm-up} {
      display: block;
    }
  }

  &-md {
    @media #{$target-md-up} {
      display: block;
    }
  }

  &-lg {
    @media #{$target-md-up} {
      display: block;
    }
  }
}

.d-none {
  display: none;

  &-sm {
    @media #{$target-sm-up} {
      display: none;
    }
  }

  &-md {
    @media #{$target-md-up} {
      display: none;
    }
  }

  &-lg {
    @media #{$target-md-up} {
      display: none;
    }
  }
}
