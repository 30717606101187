@import "../mixins/_fontstyles.scss";

// Tables
// ==========================================================================
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  border: 0;
  padding: 0;
  vertical-align: center;
  font: inherit;
  .cell-with-avatar {
    .name {
      margin-left: 6px;
    }
  }
}

table {
  margin-bottom: $spacing-sm;
  background-color: $white;
  width: 100%;
  border-collapse: collapse; // Prevent double borders
  border-spacing: 0; // Prevent double borders
}

tr {
  &:nth-child(even) {
    background-color: $gray-100;
  }
}

th,
td {
  border: 0;
  padding: 10px 10px;
  height: 60px;
  text-align: left;
  font-size: $font-size-sm;
  font-weight: $font-weight;

  &:first-child {
    @include label-small-regular-12;
  }
}

th {
  padding: 15px 10px;
  color: $black;
  font-weight: $font-weight-semibold;
}

td {
  color: $black;
}

caption {
  padding-top: 0;
  padding-bottom: 0;
  caption-side: bottom;
  text-align: left;
  color: $font-color;
}
