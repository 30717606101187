// IMPORTS
@import "../../../styles/variables/variables";
@import "../../../styles/functions/functions";
@import "../../../styles/mixins/mixins";

// VARIABLES

// SCSS
.PropertyOverview {
  .section-title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 25px;

    .section-title {
      margin-right: 10px;
    }
  }
}
