@import "../../../styles/variables/colors";

.UploadImage {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 10px 5px;

  .image {
    height: 179px;
    width: 179px !important;
    border-radius: 100%;
    background-color: $white;
    padding: 5px;
    cursor: pointer;
    margin-right: 30px;
    aspect-ratio: 1;
    .line {
      height: 100%;
      flex: 1;
      border-radius: 100%;
      border: 1px solid $gray-300;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        padding: 0px;
        height: 63px;
        width: 63px;
      }
      svg {
        stroke-width: 1px !important;
        stroke: $gray-400;
      }
    }

    @media screen and (max-width: 480px) {
      height: 120px;
      width: 120px !important;
    }
  }

  .hint {
    margin-top: 10px;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .image {
      margin-right: 0px;
      margin-bottom: 20px;
    }

    .hint {
      text-align: center;
    }
  }
}
