@import "../../../styles/variables/variables";

.Credentials {
  .formRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 30px;
    gap: 12px;

    .input-wrapper {
      margin-top: 0;
      margin-bottom: 0;
    }

    button {
      margin-top: 24px;
    }
  }

  .submit {
    float: right;
    width: 16.9em;
  }
}
