// HELPER CLASSES
// ==========================================================================
@import "accessability";
@import "clickables";
@import "display";
@import "forms";
@import "grid";
@import "horizontal-rules";
@import "tables";
@import "typography";

// Layout
// ==================================================
.no-margin {
  margin: 0 !important;
}

.half-width {
  width: 50%;
}

.no-padding {
  padding: 0 !important;
}

.flex-grow-1 {
  flex: 1 1 auto;
}

// Background Colors
// ==================================================
@each $name, $color in $colors {
  .bkg-#{$name} {
    background-color: $color !important;
  }
}

// lists
// ==================================================
.inline-list {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  list-style: none;
}


// Tables
// ==================================================
.selectable-row:hover {
  background: $blue-bkg;
  cursor: pointer;
  color: $blue;
}

// Themes
// ==================================================
.theme-light {
  @include theme-light;
}

.theme-dark {
  @include theme-dark;
}

.theme-info {
  @include theme-info;
}

.theme-danger {
  @include theme-danger;
}

.theme-warning {
  @include theme-warning;
}

.theme-success {
  @include theme-success;
}


