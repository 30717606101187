// Primary Color Palette
// ==================================================
$red: rgba(240, 76, 92, 1); // +
$orange: rgb(255, 149, 25);
$yellow: rgb(255, 192, 20);
$lime: rgba(134, 188, 37, 1); // +
$green: rgba(92, 193, 165, 1); // +
$teal: rgba(0, 132, 137, 1); // +
$cyan: rgb(0, 167, 234);
$blue: rgb(0, 131, 202);
$indigo: rgb(136, 90, 248);
$purple: rgb(165, 67, 153);
$violet: rgb(199, 21, 141);

// Brand Colors
// ==================================================
$brand-primary: $red; // +
$brand-secondary: $teal; // +

// Primary Gray Scale Palette
// ==================================================
$white: white; // +
$black: black; // +
$gray: rgba(172, 172, 172, 1); // +

$gray-100: rgba(249, 249, 249, 1); // +
$gray-200: rgba(241, 241, 241, 1); // +
$gray-300: rgba(231, 231, 231, 1); // +
$gray-400: rgba(195, 195, 195, 1); // +
$gray-500: $gray;
$gray-600: hotpink;
$gray-700: rgba(99, 99, 99, 1); // +
$gray-800: rgba(63, 76, 87, 1); // +
$gray-900: hotpink;

// RED
$red-bkg: mix($red, white, 10%);
$red-light: lighten($red, 10%);
$red-dark: darken($red, 15%);

// VIOLET
$violet-bkg: mix($violet, white, 10%);
$violet-light: lighten($violet, 15%);
$violet-dark: darken($violet, 15%);

// PURPLE
$purple-bkg: mix($purple, white, 10%);
$purple-light: lighten($purple, 15%);
$purple-dark: darken($purple, 15%);

// INDIGO
$indigo-bkg: mix($indigo, white, 10%);
$indigo-light: lighten($indigo, 15%);
$indigo-dark: darken($indigo, 15%);

// BLUE
$blue-bkg: mix($blue, white, 8%);
$blue-light: lighten($blue, 15%);
$blue-dark: darken($blue, 15%);

// CYAN
$cyan-bkg: mix($cyan, white, 8%);
$cyan-light: lighten($cyan, 15%);
$cyan-dark: darken($cyan, 15%);

// TEAL
$teal-bkg: mix($teal, white, 8%);
$teal-light: lighten($teal, 5%);
$teal-dark: darken($teal, 10%);

// GREEN
$green-bkg: mix($green, white, 8%);
$green-light: lighten($green, 12%);
$green-dark: darken($green, 10%);

// LIME
$lime-bkg: mix($lime, white, 8%);
$lime-light: lighten($lime, 12%);
$lime-dark: darken($lime, 10%);

// YELLOW
$yellow-bkg: mix($yellow, white, 8%);
$yellow-light: lighten($yellow, 20%);
$yellow-dark: darken($yellow, 10%);

// ORANGE
$orange-bkg: mix($orange, white, 9%);
$orange-light: lighten($orange, 15%);
$orange-dark: darken($orange, 17%);

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue-bkg": $blue-bkg,
    "blue-light": $blue-light,
    "blue": $blue,
    "blue-dark": $blue-dark,
    "indigo-bkg": $indigo-bkg,
    "indigo-light": $indigo-light,
    "indigo": $indigo,
    "indigo-dark": $indigo-dark,
    "purple-bkg": $purple-bkg,
    "purple-light": $purple-light,
    "purple": $purple,
    "purple-dark": $purple-dark,
    "violet-bkg": $violet-bkg,
    "violet-light": $violet-light,
    "violet": $violet,
    "violet-dark": $violet-dark,
    "red-bkg": $red-bkg,
    "red-light": $red-light,
    "red": $red,
    "red-dark": $red-dark,

    "orange": $orange,
    "yellow": $yellow,

    "lime-bkg": $lime-bkg,
    "lime-light": $lime-light,
    "lime": $lime,
    "lime-dark": $lime-dark,
    "green-bkg": $green-bkg,
    "green-light": $green-light,
    "green": $green,
    "green-dark": $green-dark,
    "teal-bkg": $teal-bkg,
    "teal-light": $teal-light,
    "teal": $teal,
    "teal-dark": $teal-dark,
    "cyan-bkg": $cyan-bkg,
    "cyan-light": $cyan-light,
    "cyan": $cyan,
    "cyan-dark": $cyan-dark,

    "white": $white,
    "gray": $gray-500,
    "gray-dark": $gray-800,
    "black": $black,
  ),
  $colors
);

// Utility Colors
// ==================================================
$primary: $red;
$secondary: $teal;
$success: $green; // +
$info: $teal;
$warning: $orange;
$danger: $red; // +
//$light:         $gray-100;
//$dark:          $gray-900;

// Theme Colors
// ==================================================
$danger-bkg: rgba($danger, 0.1);
$warning-bkg: rgba($warning, 0.15);
$info-bkg: rgba(117, 157, 162, 0.1); // +
$success-bkg: rgba($success, 0.2);

// Helper Colors
// ==================================================
$hover: rgba($white, 0.8);
$selected: rgba($white, 0.6);

// Gradients
// ==================================================

// SOCIAL NETWORK BRAND COLORS
// ==================================================
$facebook-blue: #3b5998; // +
$twitter-blue: #1da1f2; // +
$linkedin-blue: #2867b2; // +
$pinterest-red: #e60023; // +

.gray-700 {
  color: $gray-700;
}

.black {
  color: $black;
}
