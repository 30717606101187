.master-pin-cell {
  .master-pin-mask {
    width: 8rem;
    margin-right: 0.5rem;

    &-hidden {
      font-size: 1.25em;
      transform: translateY(15%);
      letter-spacing: 1px;
    }
  }

  .icon {
    cursor: pointer;
    margin-left: 4px;
  }
}
