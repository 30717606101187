@import "../../styles/variables/variables";
@import "../../styles/mixins/fontstyles";

.CrashScreen {
  display: flex;
  padding: 50px;
  justify-content: center;
  align-items: center;
  height: 100%;
}
