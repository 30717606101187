// IMPORTS
@import "../../../styles/variables/variables";
@import "../../../styles/functions/functions";
@import "../../../styles/mixins/mixins";
@import "../../../styles/mixins/fontstyles";

// VARIABLES

// SCSS
.PendingVendorInvitesPanel {
  .data-grid-row {
    .hidden-actions {
      width: 4em;
      display: flex;
      flex-direction: row;
    }

    .hidden-actions button {
      display: none;
    }

    &:hover .hidden-actions button {
      display: block;
    }
  }
}

.EditInviteModal {
  h4 {
    margin-bottom: 1em;
  }

  .input-wrapper {
    margin-top: 0;
    min-width: 22em;
    display: flex;
    flex-direction: column;
  }

  .footer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: flex-end;
  }
}
