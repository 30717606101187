// layouts/People/People.style.scss

// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.filters {
  display: flex;
  min-height: 100vh;
  height: 100%;
  padding: 15px 20px;

  .filters-header {
    margin-bottom: 30px;
  }

  .filters-content {
    display: block;
    flex: 1;
    overflow-y: auto;

    .input-wrapper {
      margin-top: 0px;
      margin-bottom: 15px;
    }
  }

  > .col {
    display: flex;
    flex-direction: column;
  }

  &-top-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h6 {
      color: $black;
      margin-bottom: 0px;
    }
    button {
      height: fit-content;
      padding: 0px;
    }
  }
  &-content {
    display: flex;
  }

  &-bottom-control {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    button:last-child {
      color: $green;
    }
  }
}
