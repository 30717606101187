// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// SCSS
.export {
  .export-table {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: $black;
    &:hover {
      opacity: 0.7;
      color: $black;
    }
    .link-disabled {
      color: $gray-700;
    }
  }
}
