// layouts/People/People.style.scss

// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.reports-layout {
  .tabbar-place {
    height: 45px;
    > ul.tabbar {
      margin: 0px;

      width: 100%;
    }

    > button.btn {
      margin-left: 23px;
    }
  }

  > .section:first-child {
    padding-bottom: 0px;

    .row {
      align-content: center;
      .col:last-child {
        display: flex;
        align-items: flex-end;

        > div {
          cursor: pointer;
          display: flex;
          text-align: end;
          justify-content: flex-end;
          align-items: center;
          color: $black;
          &:hover {
            opacity: 0.7;
          }
          .icon {
            margin-right: 4px;
          }
        }
      }
    }
  }
  > .section:last-child {
    padding-top: 8px;

    .section {
      > div.container.fluid {
        padding: 0;
      }
    }
  }
}
