// IMPORTS
@import "../../../styles/variables/variables";
@import "../../../styles/functions/functions";
@import "../../../styles/mixins/mixins";

// VARIABLES

// SCSS
.Staff {
  #property-staff-container {
    .mobile-page-header-20 {
      color: $black;
    }
  }

  .panel {
    margin-top: 0px;
  }

  .deactivatePersonButton button {
    opacity: 0;
  }

  tr:hover {
    .deactivatePersonButton button {
      opacity: 1;
    }
  }
}

.staff-header {
  margin-top: 10px;
  padding: 0px 15px;
}
