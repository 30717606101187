// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES
$modal-padding-md: 20px;

// SCSS
.modal {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  max-height: 100vh;
  overflow: hidden;
  max-width: 100vw;
  &-hidden {
    display: none;
  }

  .gobo {
    // background
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: rgba($black, 0.25);
  }

  .modal-dialogue {
    display: flex;
    flex-direction: column;
    z-index: 1;
    border-radius: $border-radius;
    background-color: rgba($white, 1);
    max-width: 700px;
    max-height: 100vh;
    overflow: hidden;
    position: relative;

    .modal-body {
      @include no-scroll-bar;
      flex: 0 1 auto;
      overflow: auto;
    }
  }

  .padding-none {
    padding: 0px;
    .horizontal-header,
    .vertical-header {
      margin-top: 20px;
    }
  }

  .padding-md {
    padding: $modal-padding-md;
  }

  .vertical-header,
  .horizontal-header {
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    h6 {
      margin-bottom: 0px;
      color: $black;
    }
    .close-btn {
      opacity: 1;
      svg,
      path {
        stroke: $gray-700 !important;
        color: $gray-700 !important;
      }
    }
  }

  .horizontal-header {
    justify-content: space-between;
    h6 {
      padding-left: 10px;
    }

    .close-btn {
      z-index: 1;
      background-color: $white;
    }
  }

  .vertical-header {
    flex-direction: column;
    justify-content: center;
    .close-btn {
      position: absolute;
      right: 0px;
      top: 0px;
    }
    h6 {
      margin-top: 20px;
    }
  }
}
