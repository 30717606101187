// IMPORTS
@import '../../styles/variables/variables';
@import '../../styles/functions/functions';
@import '../../styles/mixins/mixins';

// VARIABLES
$button-gutter-size: 10px;

// SCSS
.button-row {
  //display: flex;
  //flex-direction: row;
  width: 100%;
  max-width: 100%;

  .btn-row {
    //flex: 1 0 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 (-$button-gutter-size / 2) 0;
    padding: 0 0 $button-gutter-size 0;
    max-width: 100%;

    &.align-left {
      justify-content: flex-start;
    }

    &.align-center {
      justify-content: center;
    }

    &.align-right {
      justify-content: flex-end;
    }

    .btn,
    button[class^="btn-"] {
      margin: $button-gutter-size ($button-gutter-size / 2) 0;
    }
  }
}
