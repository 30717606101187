// IMPORTS
@import '../../styles/variables/variables';
@import '../../styles/functions/functions';
@import '../../styles/mixins/mixins';

// VARIABLES
$size: 30px;

// SCSS
.sparkline-pie {
  @include circle($size, $gray-400);
  display: inline-block;
  position: relative;
  margin: 5px;
  overflow: hidden;

  .inner-pie {
    @include circle($size, transparent);
    position: absolute;

    .complete {
      background: conic-gradient($lime 100%, $lime 0) !important;
    }
  }
}
