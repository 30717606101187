// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.RolesPermissions {
  #header {
    padding-top: 30px;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        background: none;
      }
    }
  }

  #roles-and-permissions-table {
    .role-el {
      display: flex;
      align-items: center;
      .icon {
        margin-right: 20px;
      }
      span {
        white-space: nowrap;
      }
    }

    table {
      border-collapse: separate;
      border-spacing: 0 20px !important;
      background: none;

      thead {
        background: none;
        tr {
          th {
            height: 20px;
            padding: 0 20px;
          }
        }
      }
    }
    tbody {
      tr {
        td:nth-child(2) {
          min-width: 400px;
        }
        td {
          padding: 20px;
          .name-td {
            .icon {
              margin-right: 10px;
            }
          }
          height: 80px;
          background: $white;
          .last-access {
            color: $gray-400;
          }
          .status {
            .icon {
              margin-left: 10px;
              color: $gray-300;
            }
          }
          &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
        box-shadow: 0px 4px 20px rgba(63, 76, 87, 0.05);
      }
    }
  }

  @media screen and (min-width: 1024px) {
  }

  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 480px) {
    #header {
      .container {
        flex-direction: column;
        align-items: center;
        h3 {
          margin-bottom: 10px;
        }
      }
    }
  }
}
