// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES
$alert-padding: $spacing-sm;

// sizes
$md-size: 80px;
$sm-size: 40px;
// SCSS
.avatar {
  display: flex;
  height: min-content;
  width: min-content;
  position: relative;
  justify-content: center;
  align-items: center;
}

.avatar-img {
  border-radius: $md-size;
  position: relative;
  overflow: hidden;
  all: unset;
  width: $md-size;
  height: $md-size;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;

  &.with-border {
    border: 6px solid $white;
  }
  &.md {
    width: $md-size;
    height: $md-size;
  }
  &.sm {
    width: $sm-size;
    height: $sm-size;
  }

  &.square {
    border-radius: 5px;
  }
}

.within-border-avatar {
  width: 80%;
  height: 80%;
  border: 1px solid $white;
  border-radius: $md-size;
  position: absolute;
}
