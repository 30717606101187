// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";

// VARIABLES

// SCSS
.forgot-password-container {
  display: flex;
  flex-direction: column;
}

.forgot-password-back-button {
  align-items: flex-start;
  margin-left: 20px;
}

.forgot-password {
  flex: 1 1 auto;
  max-width: 510px;
  padding: $spacing-md;

  .brand-mark {
    margin-bottom: $spacing-lg;
    height: 55px;
  }
  h2 {
    margin-bottom: 17px;
  }

  > p {
    @include body-light-14;
    color: $gray-700;
  }
}
