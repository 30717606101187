// IMPORTS
@import '../../styles/variables/variables';
@import '../../styles/functions/functions';
@import '../../styles/mixins/mixins';

// VARIABLES
$meter-size: 170;
$meter-border-width: 5;
$meter-value: 0;

// SCSS
.meter {
  --s: $meter-size;
  --b: $meter-border-width;
  --v: $meter-value;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .hole {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: calc(var(--b) * 1px);
    border-radius: 50%;
    background-color: $white;
  }

  .ring {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: calc(var(--b) * 1px) solid $gray-200;
  }

  .donut-segment {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background: conic-gradient($info calc(var(--v) * 1%), transparent 0);

    //@keyframes grow {
    //  0% {background: conic-gradient($info 0, transparent 0);}
    //  100% {background: conic-gradient($info calc(var(--v) * 1%), transparent 0);}
    //}
    //animation: grow 3s ease-out 0s 1;
  }

  .meter-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .meter-value {
      color: $gray-800;
      font-size: calc(var(--s) * .28px);
      line-height: 1em;
      font-weight: $font-weight-light;
    }

    .meter-label {
      @include ellipsis;
      margin-top: .2em;
      padding: 0 1em;
      color: $gray;
      font-size: calc(var(--s) * .09px);
      line-height: 1em;
      font-weight: $font-weight-medium;
      letter-spacing: -.4px;
    }
  }
}
