// IMPORTS
@import '../../styles/variables/variables';
@import '../../styles/functions/functions';
@import '../../styles/mixins/mixins';

// VARIABLES

// SCSS
.card-foot {
  display: block;
  border-top: 1px solid $gray-300;
  background: $white;
  padding: 0 $spacing-md;
  overflow: hidden;
}

