// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/variables/colors";

// VARIABLES

// SCSS
.input-text {
  position: relative;

  input {
    position: relative;
    background-color: $gray-100;
    &.is-invalid {
      border-color: $danger;
      background-color: $danger-bkg;
      color: $danger;
    }

    &.prefix {
      padding: {
        left: $input-height;
      }
    }

    &.suffix {
      padding: {
        right: $input-height;
      }
    }
    &:focus {
      background-color: $white;
      border-color: $gray-700;
    }
    outline: none !important;
  }

  .input {
    &-size-md {
      height: $input-height-md;
    }

    &-size-xs {
      padding-top: 0px;
      padding-bottom: 0px;
      height: $input-height-xs;
    }
  }

  .input-prefix-icon {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: $input-height;
    color: $gray-700;
  }

  .input-suffix-icon {
    cursor: pointer;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    width: $input-height;
    color: $gray-700;
    &:active {
      color: $gray-300;
    }
  }

  .clear-input {
    position: absolute;
    top: 10px;
    right: 5px;
    bottom: 0;
  }

  .toggle-password-btn {
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    background: $white;
    height: unset;
    color: $gray;

    &:hover {
      color: $blue;
    }
  }

  &.theme-gray {
    input {
      background-color: $gray-200;
    }
  }

  &.theme-white {
    input {
      background-color: $white;
    }
  }

  &.theme-default-inverted {
    input {
      background-color: $white;

      &:focus {
        background-color: $gray-100;
        border-color: $gray-700;
      }
    }
  }
}
