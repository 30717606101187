// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";

// VARIABLES

// SCSS
.tab {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 -1px;
  border: 2px solid transparent;
  background: transparent;
  padding: 0px 15px;
  padding-bottom: 4px;
  line-height: 30px;
  color: $gray-700;
  font-weight: $font-weight-light;
  text-decoration: none;

  @include body-light-14;
  &:focus,
  &:hover,
  &:active {
    outline: none;
    color: $gray-800;
  }
  &.selected {
    color: $black;
    font-weight: $font-weight-semibold;
    position: relative;
    @include desktop-label-semibold-14;
    outline: none;

    &::after {
      bottom: 13px;
      left: 0;
      position: absolute;
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background-color: $teal;
      content: "";
      transform: translateY(15px);
    }
    &.danger {
      &::after {
        background-color: $danger;
      }
    }
  }

  .badge {
    margin-left: 5px;
  }

  @media screen and (max-width: 480px) {
    padding: 0px 10px;
  }
}
