// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/variables/colors";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS

.spinner {
  display: flex;
  align-items: center;
  color: $teal;
  font-size: $font-size-lg;
  font-weight: $font-weight-semibold;

  .icon {
    @keyframes rotate {
      from {
        transform: rotate(-360deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    animation: rotate 1.5s steps(8) infinite;
  }

  &-full-screen {
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &-overlay {
    z-index: 100;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba($color: $white, $alpha: 0.75);
  }
}
