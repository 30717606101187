// IMPORTS
@import '../../styles/variables/variables';
@import '../../styles/functions/functions';
@import '../../styles/mixins/mixins';

// VARIABLES
$menu-min-width: 260px;
$dropdown-spacer: 0;
$dropdown-padding-y: 0;
$dropdown-padding-x: 0;
$menu-item-height: 40px;

// SCSS
.menu {
  margin: 0;
  border-radius: $border-radius;
  background-clip: padding-box;
  background-color: $white;
  padding: $spacing-xs;
  width: max-content;
  min-width: $menu-min-width;
  max-width: 100vw;
  overflow: hidden;
  list-style: none;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  font-size: $font-size;

  .menu-item {
    display: flex;
    align-items: center;
    margin: 0;
    cursor: pointer;
    padding: 0 $spacing-xs;
    line-height: $menu-item-height;
    color: $black;
    font-size: $font-size;

    &:hover {
      background-color: $gray-100;
    }

    .icon {
      margin-right: $spacing-xs;
    }

    .menu-item-label {

    }

    // THEMES
    &.danger {
      color: $danger;
    }
  }

  .menu-divider {
    margin: $spacing-xs 0;
    border-bottom: 1px solid $gray-300;
    height: 0;
  }
}
