// layouts/Dashboard/Dashboard.style.scss

// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.dashboard-layout {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  overflow: hidden;
  position: relative;

  .menu-btn {
    cursor: pointer;
    right: 30px;
    bottom: 30px;
    height: 50px;
    width: 50px;
    position: absolute;
    z-index: 1;
    background-color: $gray-200;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    transition: all ease-in-out 0.5s;

    .icon {
      background-color: $white;
      border-radius: 100%;
    }
    &:active {
      opacity: 0.7;
    }
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    z-index: 1000;
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  .app-body {
    // @include scrollbar;
    flex: 1 1 auto;
    overflow-y: auto;
    padding-left: 15px;
    padding-right: 15px;

    @media screen and (max-width: 768px) {
      padding-left: 5px;
      padding-right: 5px;
    }
    @media screen and (max-width: 480px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
