// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";

// VARIABLES

// SCSS
.mobile-signup-container {
  display: flex;
  flex-direction: column;
}

.mobile-signup-back-button {
  align-items: flex-start;
  margin-left: 20px;
}

.mobile-signup {
  flex: 1 1 auto;
  max-width: 510px;
  padding: $spacing-md;

  .brand-mark {
    margin-bottom: $spacing-lg;
    height: 55px;
  }

  h2 {
    margin-bottom: 17px;
  }

  > p {
    @include body-light-14;
    color: $gray-700;
  }
}

.download-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacing-md;
}
.download-mark {
  height: 40px;
}

#ios {
  margin-right: $spacing-sm;
}

.open-app-container {
  margin-top: 20px;
}

.open-app-link {
  font-weight: bold;
}
