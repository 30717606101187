// IMPORTS
@import '../../styles/variables/variables';
@import '../../styles/functions/functions';
@import '../../styles/mixins/mixins';

// VARIABLES

// SCSS
.card-head {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $gray-300;
  background: $white;
  padding: 5px $spacing-md;
  overflow: hidden;

  .card-title {
    flex: 1 0 auto;
    color: $black;
    font-size: $font-size-lg;
    font-weight: $font-weight-semibold;

    &:empty {
      display: none;
    }
  }

  .card-head-outlet {
    flex: 1 0 auto;
  }

  & + .card-body {
    padding-top: 15px;
  }
}

