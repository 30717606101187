// VISUALLY HIDDEN
// ===========================================
// Remains visible to screen readers.
@mixin visually-hidden() {
  position: absolute !important;
  margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
  border: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  clip: rect(0, 0, 0, 0) !important;
  clip-path: inset(100%) !important;
}

// Use to only display content when it's focused, or one of its child elements is focused
// (i.e. when focus is within the element/container that the class was applied to)
//
// Useful for "Skip to main content" links; see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1

@mixin visually-hidden-focusable() {
  &:not(:focus):not(:focus-within) {
    @include visually-hidden();
  }
}
