// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES
$columns: $grid-row-columns;
$prefix: "col";
$gutters: $grid-gutter-width;
$breakpoints: $grid-breakpoints;
$gutter-x: 15px;
$gutter-y: 15px;

// SCSS
.col {
  flex: 1 0 auto;
}

col,
[class^="col-"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-right: $gutter-x * 0.5;
  padding-left: $gutter-x * 0.5;
  width: 100%;
  max-width: 100%;
}

@each $breakpoint in map-keys($breakpoints) {
  //$infix: breakpoint-infix($breakpoint, $breakpoints);

  @include media-breakpoint-up($breakpoint, $breakpoints) {
    .col-#{$breakpoint} {
      flex: 1 0 0;
    }

    .col-#{$breakpoint}-auto {
      flex: 1 1 auto;
      width: auto;
    }

    @for $i from 1 through $columns {
      .col-#{$breakpoint}-#{$i} {
        flex: 0 0 auto;
        width: percentage($i / $columns);
      }
    }

    // `$columns - 1` because offsetting by the width of an entire row isn't possible
    @for $i from 0 through ($columns - 1) {
      .offset-#{$breakpoint}-#{$i} {
        //@include make-col-offset($i, $columns);
        $num: $i / $columns;
        margin-left: if($num == 0, 0, percentage($num));
      }
    }

    // Gutters
    //
    // Make use of `.g-*`, `.gx-*` or `.gy-*` utilities to change spacing between the columns.
    //@each $key, $value in $gutters {
    //  .g#{$infix}-#{$key},
    //  .gx#{$infix}-#{$key} {
    //    --#{$prefix}gutter-x: #{$value};
    //  }
    //
    //  .g#{$infix}-#{$key},
    //  .gy#{$infix}-#{$key} {
    //    --#{$prefix}gutter-y: #{$value};
    //  }
    //}
  }
}
