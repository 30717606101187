// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.TableFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  .input-wrapper {
    margin: 0px;
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
    flex-direction: column;
  }
}
