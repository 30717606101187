// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.deactivatePersonCell {
  min-width: 10em;
  position: relative;

  .deactivatePersonButton {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}
