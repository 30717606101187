// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.carousel {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}

.inner {
  height: 100%;
  white-space: nowrap;
  transition: transform 0.3s;
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 100%;
  max-width: 100%;
}

.indicators {
  width: 100%;
  justify-content: center;
  display: flex;
  bottom: 20px;

  position: absolute;
  z-index: 10;
  display: flex;
  cursor: pointer;
}

.indicators > div {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  border: 1px solid white;
  margin: 5px;
}

.indicators > div.active {
  background-color: white;
  color: #fff;
}
