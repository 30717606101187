// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.CommonAreaDetails {
  .common-area-details {
    display: flex;
    .details {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 17px;
      align-items: center;
      .information {
        .name {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          .icon {
            cursor: pointer;
          }
        }
      }

      span {
        color: black;
      }

      .actions {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
    }
  }
}
