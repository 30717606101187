// layouts/DeveloperGuide/DeveloperGuide.style.scss

// IMPORTS
@import '../../styles/variables/variables';
@import '../../styles/functions/functions';
@import '../../styles/mixins/mixins';

// VARIABLES
$sidebar-width: 315px;

// SCSS
.developer-guide {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  overflow: hidden;

  .app-body {
    @include scrollbar;
    flex: 1 1 auto;
    overflow-y: auto;
  }
}
