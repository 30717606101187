#contact-card {
  padding: 20px 30px;
  @media screen and (max-width: 480px) {
    padding: 10px 15px;
  }
}

#card-body-padding-md {
  padding: 20px 25px;
  @media screen and (max-width: 480px) {
    padding: 10px 13px;
  }
}
