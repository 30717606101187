// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES
$checkbox-group-min-width: 200px;

// SCSS
.checkbox-group {
  padding: 0;
  width: max-content;
  min-width: $checkbox-group-min-width;
  max-width: 100%;

  // THEME OPTIONS
  &-default {
    border-color: $gray-100;
    background-color: $gray-100;
  }

  &-none {
    border-color: $white;
    background-color: $white;
  }
}
