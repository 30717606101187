// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/variables/colors";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";

// VARIABLES

// SCSS

.VendorUserLayout-summary {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  // min-width: 400px;

  .delimiter {
    width: 1px;
    margin: 0 2em 0 2em;
    background-color: $gray-700;
  }

  ul {
    max-height: 20vh;
    overflow-y: auto;
  }

  p {
    font-size: 14px;
  }
}

.VendorUserLayout {
  padding-bottom: 35px;

  .breadcrumbs-container {
    @media screen and (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
      ol {
        flex-wrap: wrap;
      }
    }
  }

  .name-section {
    margin-left: 14px;

    .property {
      margin-top: 3px;
      > div:first-child {
        color: $black;
      }
      .badge {
        margin-left: 10px;
      }
    }
  }

  #overview-section {
    .add-staff-form {
      margin-top: 22px;
      display: grid;
      row-gap: 30px;
      column-gap: 20px;
      grid-template-areas:
        "input-company-name input-type"
        "input-first-name input-last-name"
        "input-phone input-email";

      @media screen and (max-width: 480px) {
        grid-template-areas:
          "input-company-name"
          "input-type"
          "input-first-name"
          "input-last-name"
          "input-phone"
          "input-email";
      }

      .input-wrapper {
        margin: 0px;
      }

      #input-company-name {
        grid-area: input-company-name;
      }

      #input-type {
        grid-area: input-type;
      }

      #input-first-name {
        grid-area: input-first-name;
      }

      #input-last-name {
        grid-area: input-last-name;
      }

      #input-phone {
        grid-area: input-phone;
      }

      #input-email {
        grid-area: input-email;
      }
    }
  }

  #access-section {
    margin-top: 37px;
  }

  #access-points-section {
    margin-top: 20px;
  }

  #actions {
    display: flex;
    margin-top: 37px;

    button:first-child {
      margin-right: 10px;
    }

    @media screen and (max-width: 480px) {
      flex-direction: column;
      button {
        width: 100%;
        &:first-child {
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }

  h6 {
    color: $black;
    margin-bottom: 0;
  }

  h4 {
    color: $black;
    margin-bottom: 0;
  }
}
