// IMPORTS
@import '../../styles/variables/variables';
@import '../../styles/functions/functions';
@import '../../styles/mixins/mixins';

// VARIABLES


// SCSS
.Hr {
    border: 0;
    border-top: 1px solid $gray-300;
    margin: 0;
}
