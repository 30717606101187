.batteryCell {
  display: flex;
  flex-direction: row;
  align-items: center;

  .battery-icon {
    svg {
      margin-bottom: -12px;
    }
  }
}
