// IMPORTS
@import "../../../../styles/variables/variables";
@import "../../../../styles/functions/functions";
@import "../../../../styles/mixins/mixins";

// VARIABLES

// SCSS
.ServiceTickets {
  .mobile-page-header-20 {
    color: $black;
  }

  .technician-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;

    .technician-name {
      margin-left: 8px;
      margin-right: 8px;
      white-space: nowrap;
    }
    .unit-number {
      font-size: 12px;
      line-height: 16px;
      white-space: nowrap;
    }
  }

  .date-cell {
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
  }
}
