// IMPORTS
@import "../../../styles/variables/variables";
@import "../../../styles/functions/functions";
@import "../../../styles/mixins/mixins";
@import "../../../styles/mixins/fontstyles";

.modal.access-schedule {
  .modal-dialogue {
    max-width: 80%;
    min-width: 300px;
  }

  .schedule-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 25px;

    .apply-schedule-button {
      margin-right: 10px;
    }
  }
}
