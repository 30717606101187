// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";
@import "../../styles/variables/colors";

// VARIABLES

.PersonCard {
  .card-content {
    display: flex;
    align-items: center;
    flex-direction: column;

    .person-name {
      margin-top: 15px;
    }

    .property-name {
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }
}
