// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";
@import "../../styles/variables/colors";

// VARIABLES

.access-schedule {
  .access-schedule-section {
    margin-top: 37px;
  }

  .access-schedule-row {
    margin-top: 20px;
  }

  .radio-buttons {
    display: flex;

    .input-radio {
      margin-right: 20px;
    }
  }

  .date-range {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 30px;
    column-gap: 20px;

    @media screen and (max-width: 480px) {
      margin-top: 30px;

      .input-wrapper {
        margin: 0px;
      }

      grid-template-columns: 1fr;
    }

    .input-wrapper {
      margin-bottom: 0px;
    }
  }

  .time-pickers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .time-picker {
      margin-top: 30px;
    }

    .start-time-picker {
      margin-right: 40px;
    }
  }
}

.week-day-picker {
  display: flex;
  flex-direction: row;
  margin-top: 30px;

  .week-day {
    flex: 1;
    max-width: 100px;
    height: 50px;
    display: flex;
    border: 1px solid $border-color;
    align-items: center;
    justify-content: center;

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    &.active {
      background-color: $brand-secondary;
      border-color: $brand-secondary;

      .label {
        @include desktop-label-semibold-14;
        color: $white;
      }
    }

    .label {
      @include body-light-14;
      color: $font-color;
    }
  }
}

// SCSS
// .input-text {
//   position: relative;

//   input {
//     position: relative;
//     background-color: $gray-100;
//     &.is-invalid {
//       border-color: $danger;
//       background-color: $danger-bkg;
//       color: $danger;
//     }

//     &.prefix {
//       padding: {
//         left: $input-height;
//       }
//     }

//     &.suffix {
//       padding: {
//         right: $input-height;
//       }
//     }
//     &:focus {
//       background-color: $white;
//       border-color: $gray-700;
//     }
//     outline: none !important;
//   }

//   .input {
//     &-size-md {
//       height: $input-height-md;
//     }

//     &-size-xs {
//       padding-top: 0px;
//       padding-bottom: 0px;
//       height: $input-height-xs;
//     }
//   }

//   .input-prefix-icon {
//     display: flex;
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     align-items: center;
//     justify-content: center;
//     width: $input-height;
//     color: $gray-700;
//   }

//   .input-suffix-icon {
//     cursor: pointer;
//     display: flex;
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     align-items: center;
//     justify-content: center;
//     width: $input-height;
//     color: $gray-700;
//     &:active {
//       color: $gray-300;
//     }
//   }

//   .clear-input {
//     position: absolute;
//     top: 10px;
//     right: 5px;
//     bottom: 0;
//   }

//   .toggle-password-btn {
//     position: absolute;
//     top: 2px;
//     right: 2px;
//     bottom: 2px;
//     background: $white;
//     height: unset;
//     color: $gray;

//     &:hover {
//       color: $blue;
//     }
//   }

//   &.theme-gray {
//     input {
//       background-color: $gray-200;
//     }
//   }

//   &.theme-white {
//     input {
//       background-color: $white;
//     }
//   }

//   &.theme-default-inverted {
//     input {
//       background-color: $white;

//       &:focus {
//         background-color: $gray-100;
//         border-color: $gray-700;
//       }
//     }
//   }
// }
