// IMPORTS
@import "../../../styles/variables/variables";
@import "../../../styles/functions/functions";
@import "../../../styles/mixins/mixins";
@import "../../../styles/variables/colors";
@import "../../../styles/mixins/fontstyles";

.modal.access-schedule {
  .schedule-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 25px;

    .apply-schedule-button {
      margin-right: 10px;
    }
  }
}

.data-grid {
  .failed-unit-row {
    background-color: rgb(255 238 238);

    .failure-label {
      font-weight: 600;
      color: $red;
    }
  }

  .pending-unit-row {
    background-color: rgb(240 252 255);

    .pending-label {
      font-weight: 600;
      color: $cyan;
    }
  }

  .unit-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 12px;
  }
}
