// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.Delete-form {
  .button-row .btn-row .btn {
    margin-top: 0px;
  }

  .btn-link {
    margin-top: 7px !important;
  }

  .error-message{
    margin-top: -25px;
    margin-bottom: 25px
  }
}
