// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SASS
.breadcrumbs-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 530px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0 1rem;

  overflow: hidden;
  list-style: none;
  flex-wrap: wrap;
  @media screen and (max-width: 530px) {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  }
}

.breadcrumbs > * {
  float: left;
  margin: 0;
  line-height: 1;
  color: $gray-700;
  font-size: $font-size-sm;
  text-decoration: none;
  line-height: 10px;

  @media screen and (max-width: 530px) {
    margin-bottom: 10px;
  }
}

.breadcrumbs > *:hover a,
.breadcrumbs > *:focus a {
  text-decoration: none;
  color: $red;
}

.breadcrumbs > * a {
  color: $gray-700;
  text-decoration: none;
}

.breadcrumbs > *.current {
  cursor: default;
  color: #333;
}

.breadcrumbs > *.current a {
  cursor: default;
  color: $gray-700;
}

.breadcrumbs > *.current:hover,
.breadcrumbs > *.current:hover a,
.breadcrumbs > *.current:focus,
.breadcrumbs > *.current:focus a {
  text-decoration: none;
}

.breadcrumbs > *.unavailable {
  color: $gray;
}

.breadcrumbs > *.unavailable a {
  color: $gray;
}

.breadcrumbs > *.unavailable:hover,
.breadcrumbs > *.unavailable:hover a,
.breadcrumbs > *.unavailable:focus,
.breadcrumbs > *.unavailable a:focus {
  cursor: not-allowed;
  text-decoration: none;
  color: $gray;
}

.breadcrumbs > *::before {
  position: relative;
  top: 1px;
  margin: 0 0.75rem;
  color: #aaa;
  content: "/";

  @media screen and (max-width: 530px) {
    margin: 0px;
    margin-right: 5px;
    content: "-";
  }
}

.breadcrumbs > *:first-child::before {
  margin: 0;
  content: " ";
}

[aria-label="breadcrumbs"] [aria-hidden="true"]::after {
  content: "/";
}

.isLast {
  color: $red;
}
