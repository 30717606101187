// Application-wide Styles

// IMPORTS
// ==========================================================================
// VARS
@import "./variables/variables";
@import "./mixins/fontstyles";

// FUNCTIONS
@import "./functions/functions";

// MIXINS
@import "./mixins/mixins";

// FONTS
// sans
@import url("https://use.typekit.net/wjm1tbu.css");
// serif
@import url("https://fonts.googleapis.com/css?family=Bree+Serif&display=swap");
// mono
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital@0;1&display=swap");
// montserrat
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
// mulish
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900;1000&display=swap");
// BASE (ATOMS)
@import "./base/base";
@import "./base/common";

// COMPONENTS (molecules)
//@import 'components/caret';

// HELPERS
@import "./helpers/helpers";

// LAYOUT
// ==========================================================================
body {
  background: $gray-100;
}

#root {
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100%;
  max-height: 100% !important;
  overflow: hidden;
}

.app-main {
  @include scrollbar();
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  max-width: 100vw;
  overflow: auto;
}

#Toaster {
  display: flex;
  position: fixed;
  right: $spacing-xs;
  bottom: $spacing-xs;
  flex-direction: column;
  justify-content: center;
  z-index: $zindex-toaster;
  //min-width: 430px;
  background-color: transparent;
  padding: 0;
  min-height: 50px;

  &:empty {
    display: none;
  }
}

// STORYBOOK
.sb-show-main.sb-main-padded {
  overflow-y: auto;

  #root {
    display: block;
    height: 100%;
    min-height: 100%;
    max-height: unset;
    overflow: auto;
  }

  #docs-root {
    display: block;
    height: 100%;
    min-height: 100%;
    max-height: unset;
    overflow: auto;
  }
}
