// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";

// VARIABLES

// SCSS
.ActivityLogOverview {
  padding-bottom: 25px;
  .breadcrumbs-container {
    @media screen and (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
      ol {
        flex-wrap: wrap;
      }
    }
  }

  #actions-section {
    .actions {
      @media screen and (max-width: 480px) {
        flex-direction: column;
        h3 {
          margin-bottom: 5px;
          margin-left: 8px;
        }
        align-items: flex-start;
      }
      #save-btn {
        margin-left: 10px;
      }
    }
  }

  .name-section {
    margin-left: 14px;

    .property {
      margin-top: 3px;
      > div:first-child {
        color: $black;
      }
      .badge {
        margin-left: 10px;
      }
    }
  }

  #guest-info-section {
    padding-bottom: 15px;
    #contact-card {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 10px;
      align-items: center;

      .first {
        grid-column: 1 / span 2;
      }

      @media screen and (max-width: 1400px) {
        grid-template-columns: repeat(5, 1fr);
      }

      @media screen and (max-width: 1270px) {
        .first {
          grid-column: 1;
        }
        grid-template-columns: 2fr repeat(1, 1fr);
      }

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      @media screen and (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
        .first > div {
          flex-direction: column;
          align-items: flex-start;
          .badge {
            margin-left: 5px;
          }
          .name-section {
            margin-top: 10px;
            margin-left: 0px;
          }
        }

        @media screen and (max-width: 299px) {
          grid-template-columns: repeat(1, 1fr);
          .first > div {
            .badge {
              margin-left: 0px;
              margin-top: 5px;
            }
          }
        }
      }

      .input-toggle {
        width: fit-content;
      }
    }
  }

  #overview-section {
    .guest-form {
      margin-top: 22px;
      display: grid;
      row-gap: 30px;
      column-gap: 20px;
      grid-template-areas:
        "input-type input-property"
        "input-guest-of input-unit"
        "input-pin input-pin"
        "input-granted-date input-expired-date";

      @media screen and (max-width: 480px) {
        grid-template-areas:
          "input-type"
          "input-property"
          "input-guest-of"
          "input-unit"
          "input-pin"
          "input-granted-date"
          "input-expired-date";
      }

      .input-wrapper {
        margin: 0px;
      }

      #input-type {
        grid-area: input-type;
      }

      #input-property {
        grid-area: input-property;
      }

      #input-guest-of {
        grid-area: input-guest-of;
      }

      #input-unit {
        grid-area: input-unit;
      }

      #input-pin {
        grid-area: input-pin;
      }

      #input-granted-date {
        grid-area: input-granted-date;
      }

      #input-expired-date {
        grid-area: input-expired-date;
      }
    }
  }

  #notes-section {
    margin-top: 28px;
    .input-wrapper {
      margin-top: 15px;
      margin-bottom: 20px;
    }

    #btn-cancel-note {
      margin-left: 10px;
    }
  }

  #activity-logs {
    margin-top: 25px;

    #logs-grid {
      padding-top: 10px;
      tr {
        .th-icon .icon {
          margin-right: 10px;
        }
        td:last-child {
          text-align: right;
          color: $gray-400;
        }
      }
    }
  }

  .lightText {
    color: $gray-500;
    @include desktop-meta-data-medium-italic-10;
  }

  h6 {
    color: $black;
    margin-bottom: 0;
  }

  h4 {
    color: $black;
    margin-bottom: 0;
  }

  .filter-form {
    padding: 0 22px;
    margin-bottom: 0px;
  }
}
