// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";
// VARIABLES

// SCSS
.login {
  flex: 1 1 auto;
  max-width: 510px;
  padding: $spacing-md;
  justify-content: center;
  align-items: center;
  .brand-mark {
    margin-bottom: $spacing-lg;
    height: 55px;
  }
  h2 {
    margin-bottom: 17px;
  }

  > p {
    @include body-light-14;
    color: $gray-700;
  }

  .button-row .btn-row {
    margin: 0px;
    width: 100%;
    .btn {
      margin: 0px;
    }
  }
  @media screen and (max-width: 768px) {
    .brand-mark {
      margin-bottom: $spacing-sm;
    }
    display: flex;
    flex-direction: column;

    p {
      text-align: center;
    }
  }

  @media screen and (max-width: 480px) {
    padding: $spacing-sm;
  }
}
