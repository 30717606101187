// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.new-password-form {
  flex: 1 1 auto;
  max-width: 600px;

  .brand-mark {
    margin-bottom: $spacing-sm;
    height: 55px;
  }

  .error-message {
    margin-top: -25px;
    margin-bottom: 25px;
  }
}
