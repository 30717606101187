// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.collapsible-checkbox-list {
  background-color: $gray-100;
  padding: 10px 20px 10px 20px;
  height: auto;
  transition: all 0.25s ease-out;
  .collapsible-list {
    padding-left: 0px;
    margin: 0px;

    li {
      list-style-type: none;
    }
  }
  button {
    height: auto;
    padding: 0px;
  }
}
