// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";
@import "../../styles/variables/colors";

// VARIABLES

.time-picker {
  .time-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .input-wrapper {
    margin: 0;
    width: 50px;

  }

  .time-delimiter {
    color: $black;
    margin: 0 5px 0 5px;
  }

  .daytime-group {
    display: flex;
    flex-direction: row;
    margin-left: 22px;

    .option {
      height: 50px;
      display: flex;
      border: 1px solid $border-color;
      align-items: center;
      justify-content: center;
      width: 50px;

      &:first-child {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }

      &:last-child {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }

      &.active {
        background-color: $brand-secondary;
        border-color: $brand-secondary;

        .label {
          @include desktop-label-semibold-14;
          color: $white;
        }
      }

      .label {
        @include body-light-14;
        color: $font-color;
      }
    }
  }
}