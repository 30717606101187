// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.paginator {
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  gap: 10px;
  .btn:focus {
    outline: none;
  }

  .btn {
    border: 0px;

    svg {
      scale: 1.3;
    }
  }
  @media screen and (max-width: 480px) {
    .btn {
      height: 25px;
      width: 25px !important ;
    }
    gap: 5px;
  }
}
