// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.UnitDetails {
  #navigation .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  #contact {
    .contact-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .main-info {
        display: flex;
        align-items: center;
        margin-right: 25px;
        position: relative;
        .badge {
          padding: 0px 10px;
          margin-right: 15px;
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .actions-staff {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > div {
          display: flex;
          align-items: center;
        }
        .number {
          margin-left: 10px;
          margin-right: 20px;
        }
        .phone {
          cursor: pointer;
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        .main-info {
          margin-right: 0px;
          align-items: flex-start;
        }
        .actions-staff {
          flex-direction: column;
          bottom: 30px;
          width: 100%;
          padding: 0px 5px;
          .btn {
            min-width: 150px;
            margin: 10px 0px;
            flex: 1;
            width: max-content;
          }

          .number {
            margin-right: 0px;
          }
        }
      }
      @media screen and (max-width: 480px) {
        .main-info {
          align-items: center;
          flex-direction: column;
          h3,
          span {
            text-align: center;
          }

          .actions-staff {
            flex-direction: column;
            align-items: flex-start;
            .btn {
              margin: 10px 0px;
              flex: 1;
              width: max-content;
            }
          }
        }
      }
    }
  }

  #unit-details {
    .unit-cards-grid {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "card1 card2"
        "card3 card4";
    }

    .card1 {
      grid-area: card1;
    }

    .card2 {
      grid-area: card2;
    }

    .card3 {
      grid-area: card3;
    }

    .card4 {
      grid-area: card4;
    }

    .card1,
    .card2,
    .card3,
    .card4 {
      background-color: $white;
      padding: 15px 21px;
    }

    .content {
      height: 100%;
      > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        a {
          text-decoration: none;
          color: $red;
        }
      }
    }

    .card3,
    .card4 {
      .icon {
        margin-right: 15px;
      }
    }

    @media screen and (max-width: 768px) {
      .unit-cards-grid {
        grid-template-columns: 1fr;
        grid-template-areas:
          "card1"
          "card2"
          "card3"
          "card4";
        gap: 15px;
      }
    }

    @media screen and (max-width: 480px) {
      .unit-cards-grid {
        gap: 5px;
      }

      .card1,
      .card2,
      .card3,
      .card4 {
        background-color: $white;
        padding: 5px 10px;
      }

      .card3,
      .card4 {
        .icon {
          margin-right: 10px;
        }
      }
    }
  }

  #table {
    tbody tr td:first-child .icon {
      margin-right: 10px;
    }

    h4 {
      margin-bottom: 15px;
    }
    #activity-logs {
      .container {
        padding: 0px;
      }
    }
  }
}
