// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.input-textarea {
  .gray {
    background-color: rgba(215, 220, 224, 0.3);
    border-width: 0px;
    &::placeholder {
      color: $gray-400;
    }
  }
}
