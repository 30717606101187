// IMPORTS
@import "../../../styles/variables/variables";
@import "../../../styles/functions/functions";
@import "../../../styles/mixins/mixins";

// VARIABLES

// SCSS
.Units {
  .mobile-page-header-20 {
    color: $black;
  }
}
