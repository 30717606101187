// IMPORTS
@import "../../../styles/variables/variables";
@import "../../../styles/functions/functions";
@import "../../../styles/mixins/mixins";

// VARIABLES

// SCSS
.ImportFailures {
  .missing-import-field {
    color: $red;
    font-weight: 600;
  }
}
