// IMPORTS
@import "../../../styles/variables/colors";

// VARIABLES

// SCSS
.filter-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  & > * {
    margin: 0 5px;
  }

  .filter-btn-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    flex: 1;
    .red-dot {
      position: absolute;
      top: 3px;
      right: 1px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $red;
    }
  }

  #search-actions {
    margin-top: 0px;
    margin-bottom: 0px;
    input {
      height: 40px;
      border-width: 0px;
      background: rgba(215, 220, 224, 0.3);
    }
    .input-label {
      display: none;
    }
  }
  .btn {
    min-width: 116px;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    padding: 0 14px;
  }
  .sort-box {
    display: flex;
    align-items: center;
    height: 50px;
    p {
      white-space: nowrap;
      margin: 0px 6px;
    }

    #select {
      width: fit-content;
      height: fit-content;
      border: 0;
      .input-label {
        margin-bottom: 0px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin: 0px 0px;
    flex-direction: column;
    align-items: start;
    .btn {
      width: 100%;
      margin: 15px 0px;
    }
    #staff-search {
      margin: 10px 0px;
    }

    #filters-btn-open {
      margin-bottom: 5px;
    }

    .sort-box {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0px 5px;
      height: 50px;
      p {
        margin: 0px;
      }
    }
  }
  .panel {
    margin-top: 0px;
  }

  .view-toggle {
    display: flex;
    height: 40px;

    background: #edf0f2;
    border-radius: 5px;

    .option-container {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 32px;
      margin: 4px;

      border-radius: 4px;

      &.active {
        background: $white;
      }
    }
  }
  #people-search {
    margin: 0px;
    height: 40px;
    .input-label {
      margin-bottom: 0px;
    }
  }
}
