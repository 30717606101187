// layouts/People/People.style.scss

// IMPORTS
@import "../../../styles/variables/variables";
@import "../../../styles/functions/functions";
@import "../../../styles/mixins/mixins";

// VARIABLES

// SCSS
.filters {
  background-color: $white;
  display: flex;
  min-height: 100vh;
  padding: 15px 20px;
  .filters-content {
    > .input-wrapper {
      margin-bottom: 10px !important;
    }
  }
}
