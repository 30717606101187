// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.Properties {
  max-width: 100vw;

  .page-title {
    padding-top: 15px;
  }
  .property-card {
    flex: 1;
    margin-bottom: 20px;

    &:nth-child(odd) {
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 1400px) {
    .property-card {
      flex: 1;
      flex-basis: 100%;

      &:nth-child(odd) {
        margin-right: 0px;
      }
    }
  }
}
