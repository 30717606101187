@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";

.BrivoTenants {
  &-form {
    position: relative;
  }

  .submit-button {
    width: 100%;
    align-self: flex-end;
  }

  .error-message-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .error-message {
    color: $red;
  }

  .error-icon {
    color: $red;
    margin-top: -0.25rem;
  }
}
