// IMPORTS
@import "../../../styles/variables/variables";
@import "../../../styles/functions/functions";
@import "../../../styles/mixins/mixins";

// VARIABLES

// SCSS
.OtherAccess {
  .mobile-page-header-20 {
    color: $black;
  }

  .col {
    padding-left: 0px;
    padding-right: 0px;
  }
  .filter-form {
    padding-left: 20px;
    padding-right: 20px;
  }

  #other-access {
    table {
      border-collapse: separate;
      border-spacing: 0 20px !important;
      background: none;
      padding-left: 10px;
      padding-right: 10px;

      thead {
        background: none;
        tr {
          th {
            height: 20px;
            padding: 0 20px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 20px;
          .name-td {
            .icon {
              margin-right: 10px;
            }
          }
          height: 80px;
          background: $white;
          .last-access {
            color: $gray-400;
          }
          .status {
            .icon {
              margin-left: 10px;
              color: $gray-300;
            }
          }
          &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
        box-shadow: 0px 4px 20px rgba(63, 76, 87, 0.05);
      }
    }
  }
}

.other-access-header {
  margin-top: 10px;
  padding: 0px 20px;
  .btn .icon {
    padding: 12px;
  }
}
