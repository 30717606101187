@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";

.Overview {
  .header {
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;

    h3 {
      flex: 1;
    }

    #profile-select {
      margin: 0;
      max-width: 18em;

      label {
        display: none;
      }
    }
  }

  .card-body.padding-undefined {
    padding: 20px;
    .icon.lg {
      margin-right: 15px;
    }
  }

  .occupancy {
    transform: translateY(-10px);
    .input-wrapper {
      margin: 0;
    }

    .input-wrapper,
    select {
      max-width: 184px;
    }
  }

  .metrics-occupancy {
    padding-top: 33px;
    max-width: 361px;
    width: 100%;
    justify-self: center;
    .metrics-occupancy-statistic {
      div:first-child p {
        margin-bottom: 25px;
      }
      p {
        margin-top: 6px;
        color: $gray-700;
        @include body-small-light-12;
      }
    }
  }
}
