// layouts/Devices/Tile.style.scss

// IMPORTS
@import "../../../styles/variables/variables";
@import "../../../styles/functions/functions";
@import "../../../styles/mixins/mixins";

// VARIABLES

// SCSS
.Tile {
  margin-top: 30px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  @media screen and (max-width: 1440px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  .box {
    background-color: $white;
    padding: 20px;
    box-shadow: 0px 4px 20px 0px #3f4c570d;
    border-radius: 5px;
    cursor: pointer;
    &:active {
      opacity: 0.7;
    }
    .info {
      margin-left: 15px;
    }

    .icon {
      margin-left: 0px;
    }
  }
}
