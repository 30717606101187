// IMPORTS
@import '../../styles/variables/variables';
@import '../../styles/functions/functions';
@import '../../styles/mixins/mixins';

// VARIABLES
$button-gutter-size: 10px;

// SCSS
.button-group {
  display: inline-flex;
  flex-direction: row;

  .btn-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0 (-$button-gutter-size / 2) 0;
    padding: 0 0 $button-gutter-size 0;
    width: min-content;
    max-width: 100%;

    .btn {
      border-radius: 0;
    }

    .btn:first-child {
      border-radius: $border-radius 0 0 $border-radius;
    }

    .btn:last-child {
      border-radius: 0 $border-radius $border-radius 0;
    }

    .btn + .btn {
      border-left: 1px solid rgba($white, .5);
    }
  }
}
