// IMPORTS
@import '../../styles/variables/variables';
@import '../../styles/functions/functions';
@import '../../styles/mixins/mixins';

// VARIABLES


// SCSS
#FlyoutMenu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .gobo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($black, .25);
  }

  .flyout-menu {
    @include scrollbar;
    position: absolute;
    top: 0;
    right: 0;
    background-color: $white;
    padding: $spacing-xs;
    width: 340px;
    max-width: 100vw;
    max-height: 100vw;
    overflow-y: auto;
  }
}
