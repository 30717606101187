// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";

.profile-header {
  .mobile-page-header-20 {
    color: $black;
  }

  .person-card {
    .name-section {
      margin-left: 14px;

      &-info {
        margin-top: 3px;
        margin-right: 10px;

        &-subtitle {
          color: $black;
        }
      }
    }
  }

  .person-card-info {
    gap: 10px;
  }
}
