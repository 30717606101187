// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
#back-btn {
  .icon {
    padding: 8px 0px;
    width: 20px;
  }
}
