// IMPORTS
@import "../../../styles/variables/variables";
@import "../../../styles/functions/functions";
@import "../../../styles/mixins/mixins";

// VARIABLES

// SCSS
#form-message-setup {
  input {
    background: $white !important;
  }

  #email {
    max-width: 523px;
  }

  #onboarding-message {
  }
}

.message-setup-header {
  margin-top: 10px;
  padding: 0px 20px;
  #save-btn {
    margin-left: 10px;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    padding: 0px 7px;
    h4 {
      margin-bottom: 10px;
      text-align: center;
    }
    > div {
      width: 100%;
      flex-direction: column;

      #save-btn {
        margin-left: 0px;
        margin-top: 10px;
      }

      .btn {
        width: 100%;
      }
    }
  }
}
