// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";

// VARIABLES
$badge-size-xs: 18px; // +
$badge-size-sm: 20px; // +
$badge-size-md: 25px; // +
$badge-size-lg: 18px;
$badge-size-xl: 60px; // +

// SCSS
.badge {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: $border-radius;
  background: $gray-100;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 5.5px;
  padding-bottom: 5.5px;
  min-width: $badge-size-md;
  letter-spacing: 0.02em;
  white-space: nowrap;
  color: $font-color;
  font-size: $font-size;
  font-weight: $font-weight-medium;
  line-break: strict;
  height: fit-content;
  // SIZES
  &.xs {
    min-width: $badge-size-xs;

    line-height: $badge-size-xs;
    font-size: 10px;
  }

  &.sm {
    min-width: $badge-size-sm;
    @include tag-semibold-10;
  }

  &.md {
    min-width: $badge-size-md;
    line-height: $badge-size-md;
    font-size: $font-size;
  }

  &.lg {
    min-width: $badge-size-lg;
    line-height: $badge-size-lg;
    font-size: $font-size-lg;
    font-weight: $font-weight-semibold;
  }

  &.xl {
    min-width: $badge-size-xl;

    line-height: $badge-size-xl;
    font-size: 30px;
  }

  // THEMES
  &.default {
    border-color: $gray-200;
    background: $gray-200;
    color: $gray-500;
  }

  &.primary {
    border-color: $brand-primary;
    background: $brand-primary;
    color: $white;
  }

  &.secondary {
    border-color: $brand-secondary;
    background: $brand-secondary;
    color: $white;
  }

  &.info {
    border-color: $info-bkg;
    background-color: $info-bkg;
    color: $info;
  }

  &.success {
    border-color: $success-bkg;
    background-color: $success-bkg;
    color: $success;
  }

  &.warning {
    border-color: $warning-bkg;
    background: $warning-bkg;
    color: $warning;
  }

  &.danger {
    border-color: $danger-bkg;
    background: $danger-bkg;
    color: $danger;
  }

  &.dark {
    background-color: rgba($color: #737d85, $alpha: 0.1);
    background-color: rgba($color: #737d85, $alpha: 0.1);
    color: $gray-700;
  }

  &.outline {
    border-color: $blue;
    background-color: transparent;
    color: $blue !important; // sass-lint:disable-line no-important
  }

  // SHAPES
  &.pill {
    border-radius: 1em;
    padding: 0 0.5em;
  }
}
