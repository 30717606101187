// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES
$sidebar-width: 313px;
$sidebar-width-mobile: 200px;
$sidebar-width-closed: 60px;
$sidebar-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 400px,
) !default;

// SCSS

.sidebar {
  display: flex;
  flex-direction: row;
  background: $white;
  width: $sidebar-width-mobile;
  min-width: $sidebar-width-mobile;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  transition: all ease-in-out 0.5s;

  @media #{$target-md-up} {
    width: $sidebar-width;
    min-width: $sidebar-width;
  }

  .sidebar-inner {
    display: flex;
    flex-direction: column;
    width: $sidebar-width-mobile;
    max-height: 100%;
    overflow: hidden;

    @media #{$target-md-up} {
      width: $sidebar-width;
    }

    .sidebar-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: $spacing-md;
      height: $header-height;

      .brand {
        height: 30px;
      }
    }

    .sidebar-body {
      > div:last-child {
        display: flex;
        justify-content: center;
      }
      @include no-scroll-bar;
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      padding: 10px;
      overflow-y: auto;

      .panel {
        display: flex;
        flex-direction: column;
        padding: 20px;
        align-items: center;
        justify-content: center;
        max-width: 290px;
        h5 {
          text-align: center;
          margin-bottom: 0px;
        }
        p {
          text-align: center;
          margin-bottom: 0px;
        }
        button {
          margin-top: 15px;
          max-width: 183px;
        }
      }
    }

    .sidebar-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $spacing-xs;

      .dropdown {
        flex: 1 1 auto;
        .dropdown-toggle {
          display: flex;
          justify-content: center;
          padding-bottom: $spacing-xs;
        }
      }

      .base-user-info {
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        // background-color: red;
      }
    }
  }
}

.sidebar-closed {
  @media screen and (max-width: 1024px) {
    position: absolute;
    top: 0px;
    left: -$sidebar-width;
    position: absolute;
    max-width: $sidebar-width;
    width: 100%;
    height: 100vh;
    z-index: 2;
    .sidebar-inner {
      width: $sidebar-width;
    }
  }
}

.sidebar-open {
  @media screen and (max-width: 1024px) {
    position: absolute;
    top: 0px;
    left: 0px;
    position: absolute;
    z-index: 2;
    height: 100vh;
    max-width: $sidebar-width;
    width: 100%;
    .sidebar-inner {
      width: 100%;
    }
  }
}

.user-menu-toggle {
  display: flex;
  border-radius: $border-radius;
  background: $gray-100;
  overflow: hidden;
  padding: $spacing-xs;
  cursor: pointer;
  align-items: center;
  max-width: 253px;

  .base-user-info {
    overflow: hidden;

    .tip {
      text-overflow: ellipsis;
    }
  }

  p {
    width: 100%;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    &:first-child {
      color: $black;
    }
    &:last-child {
      color: $gray-700;
    }
  }

  &:hover {
    background: $gray-100;
  }
  > div:first-child {
    display: flex;
    align-items: center;
    max-width: calc(100% - 40px);

    > div:first-child {
      display: flex;
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
  }

  .profile-picture {
    display: flex;
    flex: none;
  }

  svg {
    stroke: $gray-400;
  }
}

.drop-shadow-sidebar {
  @media screen and (max-width: 1024px) {
    top: 0px;
    left: 0px;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 1;
    transition: all ease-in-out 0.5s;
    cursor: pointer;
  }
}
