// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.input-wrapper {
  margin: $spacing-md 0;
  width: 100%;

  &.no-margin {
    margin: 0;
  }
}
