// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";

// VARIABLES

// SCSS
.tooltip {
  max-width: 100%;
  display: inline-flex;
  position: relative;
  cursor: pointer;
  color: rgba($info, 0.75);
  font-size: $font-size-sm;

  &:hover {
    color: rgba($info, 1);
  }
}

.tip {
  padding-left: 5px;
  padding-right: 5px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: $font-weight-medium;
}
