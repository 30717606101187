// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";

// VARIABLES

// SCSS
.ResidentsOverview {
  .phone-number-section {
    margin-left: 20px;
  }

  .leaseholder-link {
    color: $red;
    font-weight: 500;
  }

  #resident-personal-info {
    #contact-card {
      @media screen and (max-width: 768px) {
        padding: 15px 15px;
        > div {
          flex-direction: column;
          align-items: flex-start;
          gap: 5px;

          > div {
            margin-left: 0px;
          }
        }
      }
    }
  }

  .action-section {
    margin-left: 20px;

    button:first-child {
      margin-right: 10px;
    }
  }

  .card-info {
    .icon {
      margin-right: 16px;
    }

    .avatar {
      all: unset;
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }

    .past-due {
      color: $red;
    }

    color: black;
    > .card {
      margin-top: 20px;
      margin-bottom: 0px;
    }
  }

  .personal-info-title {
    margin-bottom: 15px;
    p {
      margin-bottom: 0;
      margin-right: 10px;
    }
  }
  hr {
    border: none;
    border-top: 1px solid $gray-300;
  }
  .graph {
    padding-left: 10px;
    .time-in-app-statistic {
      > div:first-child {
        color: $black;
      }
      .icon {
        border-radius: 100%;
        margin-left: 12px;
        margin-right: 10px;
      }

      .precentage {
        color: $green;
      }
    }

    hr {
      margin-top: 18px;
      margin-bottom: 13px;
    }
  }
}
