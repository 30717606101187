// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/variables/typography";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/variables/colors";

// VARIABLES

// SCSS
.input-wrapper {
  .input-select {
    select {
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: $font-weight-medium;
      font-size: 14px;
      line-height: 14px;
      height: 40px;
      background-color: $gray-100;

      &:focus {
        background-color: $white;
      }

      &.size-md {
        height: $input-height-md;
      }

      &.size-xs {
        padding-top: 0px;
        padding-bottom: 0px;
        height: $input-height-xs;
      }
    }
  }

  .white {
    select {
      background-color: $white;
    }
  }
}
