.note {
  #card-body-padding-md {
    display: flex;
    column-gap: 45px;

    .name {
      margin-left: 6px;
    }

    .text {
      display: flex;
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: pre-line;
      align-items: center;
    }

    .edit-icon {
      cursor: pointer;
    }

    @media screen and (max-width: 1024px) {
      row-gap: 10px;
    }

    @media screen and (max-width: 768px) {
      row-gap: 10px;

      .text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: break-spaces;
        @supports (-webkit-line-clamp: 2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    @media screen and (max-width: 425px) {
      .text {
        @supports (-webkit-line-clamp: 4) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
