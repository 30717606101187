// IMPORTS
@import "../../styles/variables/variables";
@import "../../styles/functions/functions";
@import "../../styles/mixins/mixins";
@import "../../styles/mixins/fontstyles";

// VARIABLES

// SCSS
.VendorOverview {
  .mobile-page-header-20 {
    color: $black;
  }

  .lightText {
    color: $gray-500;
    @include desktop-meta-data-medium-italic-10;
  }

  .pl-20 {
    padding-left: 10px;
  }

  h6 {
    color: $black;
    margin-bottom: 0;
  }

  .phone-number-section {
    margin-left: 20px;
  }

  .email-section {
    margin-left: 20px;
  }

  .action-section {
    margin-left: 20px;

    button:first-child {
      margin-right: 10px;
    }
  }

  .card-info {
    .icon {
      margin-right: 16px;
    }

    .avatar {
      all: unset;
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }

    .past-due {
      color: $red;
    }

    color: black;
    > .card {
      margin-top: 20px;
      margin-bottom: 0px;
    }
  }
  .personal-info-title {
    margin-bottom: 15px;
    p {
      margin-bottom: 0;
      margin-right: 10px;
    }
  }
  hr {
    border: none;
    border-top: 1px solid $gray-300;
  }
  .graph {
    padding-left: 10px;
    .time-in-app-statistic {
      > div:first-child {
        color: $black;
      }
      .icon {
        border-radius: 100%;
        margin-left: 12px;
        margin-right: 10px;
      }

      .precentage {
        color: $green;
      }
    }

    hr {
      margin-top: 18px;
      margin-bottom: 13px;
    }
  }

  .deactivatePersonButton button {
    opacity: 0;
  }

  tr:hover {
    .deactivatePersonButton button {
      opacity: 1;
    }
  }

  @media screen and (max-width: 480px) {
    #contact-card > div {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }
}
